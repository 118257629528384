var app;
(function (app) {
    var dp;
    (function (dp) {
        var AppsMenuController = (function () {
            function AppsMenuController($http, directoryService, contextService) {
                this.$http = $http;
                this.directoryService = directoryService;
                this.contextService = contextService;
                $http.get('/SiteSettings/defualt.apps.html').then(function () {
                });
            }
            AppsMenuController.$inject = ['$http', 'app.giteditor.IGitDirectoryService', 'app.context.IContextService'];
            return AppsMenuController;
        }());
        var AppsMenuDirective = (function () {
            function AppsMenuDirective() {
                this.template = '<magic-renderer template="\'/SiteSettings/default.apps.html\'"></magic-renderer>';
                this.restrict = 'E';
            }
            return AppsMenuDirective;
        }());
        var slice = [].slice;
        function buildDirectiveFactory(ctor) {
            var factory;
            factory = function () {
                var args;
                args = 1 <= arguments.length ? slice.call(arguments, 0) : [];
                return (function (func, args, ctor) {
                    ctor.prototype = func.prototype;
                    var child = new ctor, result = func.apply(child, args);
                    return Object(result) === result ? result : child;
                })(ctor, args, function () { });
            };
            factory.$inject = ctor.$inject;
            return factory;
        }
        ;
        angular
            .module('app.dp')
            .directive('dpAppsMenu', buildDirectiveFactory(AppsMenuDirective));
    })(dp = app.dp || (app.dp = {}));
})(app || (app = {}));
