var app;
(function (app) {
    var giteditor;
    (function (giteditor) {
        var MagicRendererDirective = (function () {
            function MagicRendererDirective($timeout, $templateRequest, $compile, gitRendererService, gitDirectoryService) {
                var _this = this;
                this.$timeout = $timeout;
                this.$templateRequest = $templateRequest;
                this.$compile = $compile;
                this.gitRendererService = gitRendererService;
                this.gitDirectoryService = gitDirectoryService;
                this.restrict = 'E';
                this.link = function (scope, element) {
                    scope.$watch('template', function () {
                        _this.render(scope, element);
                    }, true);
                    _this.render(scope, element);
                };
                this.renderPending = {};
                this.render = function (scope, element) {
                    if (_this.renderPending.hasOwnProperty(scope.$id))
                        _this.$timeout.cancel(_this.renderPending[scope.$id]);
                    _this.renderPending[scope.$id] = _this.$timeout(function () { _this._render(scope, element); }, 300);
                };
                this._render = function (scope, element) {
                    if (!scope.template) {
                        element.innerHTML = '';
                        return;
                    }
                    if (scope.template.endsWith('.html')) {
                        _this.$templateRequest(scope.template).then(function (html) {
                            var template = angular.element(html);
                            element.html(template);
                            _this.$compile(template)(scope);
                        });
                    }
                    else {
                        var compile = function (result) {
                            var template = angular.element("<div>" + result.immidate + "</div>");
                            if (result.isAsync) {
                                result.eventual.then(function (html) {
                                    template = angular.element(html);
                                    element.html(template);
                                    _this.$compile(template)(scope);
                                }).catch(function (error) {
                                    console.warn("MagicRenderer async render failed", error);
                                });
                            }
                            element.html(template);
                            _this.$compile(template)(scope);
                        };
                        _this.gitDirectoryService.loadPath(scope.template).then(function (file) {
                            var renderer = _this.gitRendererService.getRenderer(file);
                            if (!renderer.requiresContent) {
                                compile(_this.gitRendererService.renderFile(file));
                                return;
                            }
                            _this.gitDirectoryService.loadFile(file).then(function (fileContent) {
                                compile(_this.gitRendererService.render(fileContent));
                            }, function (error) { console.error('MagicRenderer failure. (gitRendererService)', error); });
                        }, function (error) { console.error('MagicRenderer failure. (gitRendererService)', error); });
                    }
                };
                this.scope = {
                    template: '=template'
                };
            }
            MagicRendererDirective.$inject = ['$timeout', '$templateRequest', '$compile', 'app.giteditor.GitRenderService', 'app.giteditor.IGitDirectoryService'];
            return MagicRendererDirective;
        }());
        var slice = [].slice;
        function buildDirectiveFactory(ctor) {
            var factory;
            factory = function () {
                var args;
                args = 1 <= arguments.length ? slice.call(arguments, 0) : [];
                return (function (func, args, ctor) {
                    ctor.prototype = func.prototype;
                    var child = new ctor, result = func.apply(child, args);
                    return Object(result) === result ? result : child;
                })(ctor, args, function () { });
            };
            factory.$inject = ctor.$inject;
            return factory;
        }
        ;
        angular
            .module('app.giteditor')
            .directive('magicRenderer', buildDirectiveFactory(MagicRendererDirective));
    })(giteditor = app.giteditor || (app.giteditor = {}));
})(app || (app = {}));
