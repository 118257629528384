var app;
(function (app) {
    var giteditor;
    (function (giteditor) {
        'use strict';
        var GitEditService = (function () {
            function GitEditService($controller) {
                this.$controller = $controller;
            }
            GitEditService.prototype.canEdit = function (meta) {
                GitEditService.ensureEditorsLoaded(this.$controller);
                return GitEditService.editors.hasOwnProperty(meta.extension);
            };
            GitEditService.prototype.registerEditor = function (extension, editor) {
                GitEditService.editors[extension] = editor;
            };
            GitEditService.ensureEditorsLoaded = function ($controller) {
                if (!GitEditService.editorsLoaded) {
                    GitEditService.editorsLoaded = true;
                    var injectable = [];
                    angular.module('app.giteditor')['_invokeQueue'].forEach(function (value) { injectable.push(value[2][0]); });
                    window.ctl = $controller;
                    var regex = new RegExp("^app.giteditor.editors");
                    for (var _i = 0, injectable_1 = injectable; _i < injectable_1.length; _i++) {
                        var controllerName = injectable_1[_i];
                        if (controllerName.match(regex)) {
                            var ctl = $controller(controllerName);
                            for (var _a = 0, _b = ctl.extensions; _a < _b.length; _a++) {
                                var ext = _b[_a];
                                GitEditService.editors[ext] = ctl;
                            }
                            if (controllerName === 'app.giteditor.editorsNullEditorController') {
                                this.defaultEditor = ctl;
                            }
                        }
                    }
                }
            };
            GitEditService.prototype.editorView = function (content) {
                if (!content)
                    return null;
                GitEditService.ensureEditorsLoaded(this.$controller);
                if (this.canEdit(content))
                    return GitEditService.editors[content.extension].view;
                return GitEditService.defaultEditor.view;
            };
            ;
            GitEditService.prototype.editorController = function (content) {
                if (!content)
                    return null;
                GitEditService.ensureEditorsLoaded(this.$controller);
                if (this.canEdit(content))
                    return GitEditService.editors[content.extension];
                return GitEditService.defaultEditor;
            };
            ;
            GitEditService.prototype.editableExtensions = function () {
                GitEditService.ensureEditorsLoaded(this.$controller);
                return Object.keys(GitEditService.editors);
            };
            GitEditService.editorsLoaded = false;
            GitEditService.editors = {};
            GitEditService.defaultEditor = null;
            GitEditService.$inject = ['$controller'];
            return GitEditService;
        }());
        giteditor.GitEditService = GitEditService;
        angular
            .module('app.giteditor')
            .service('app.giteditor.IGitEditorService', GitEditService);
    })(giteditor = app.giteditor || (app.giteditor = {}));
})(app || (app = {}));
