var app;
(function (app) {
    var dp;
    (function (dp) {
        var FileSearchController = (function () {
            function FileSearchController($q, $http, $scope, directoryService, fileSystemIconService, contextService, observeOnScope) {
                var _this = this;
                this.$q = $q;
                this.$http = $http;
                this.$scope = $scope;
                this.directoryService = directoryService;
                this.fileSystemIconService = fileSystemIconService;
                this.contextService = contextService;
                this.currentFSO = {};
                this.search = "";
                this.results = [];
                this.doSearch = function (search) {
                    var qd = _this.$q.defer();
                    if (!search.search || !search.path) {
                        qd.resolve([]);
                    }
                    else {
                        _this.$http.post('/api/search', search).then(qd.resolve, qd.reject);
                    }
                    return Rx.Observable.fromPromise(qd.promise);
                };
                this.contextService
                    .getContext("selectedFilesystemObject")
                    .distinctUntilChanged()
                    .safeApply($scope, function (fso) {
                    _this.currentFSO = fso;
                })
                    .subscribe();
                observeOnScope($scope, function () { return _this.search; })
                    .throttle(600)
                    .map(function (change) {
                    return { search: change.newValue || "", path: _this.currentFSO ? _this.currentFSO.fullname : '' };
                })
                    .distinctUntilChanged()
                    .flatMapLatest(this.doSearch)
                    .safeApply($scope, function (result) {
                    if (result)
                        _this.results = result.data;
                })
                    .subscribe();
            }
            FileSearchController.prototype.goTo = function (item) {
                this.directoryService.goPath("/" + item.fullname);
            };
            FileSearchController.prototype.icon = function (item) {
                return this.fileSystemIconService.getIcon(item);
            };
            FileSearchController.$inject = ['$q', '$http', '$scope', 'app.giteditor.IGitDirectoryService', 'app.giteditor.IFileSystemIconService', 'app.context.IContextService', 'observeOnScope'];
            return FileSearchController;
        }());
        var FileSearchService = (function () {
            function FileSearchService($q, $http, directoryService, fileSystemIconService, contextService, observeOnScope) {
                var _this = this;
                this.$q = $q;
                this.$http = $http;
                this.directoryService = directoryService;
                this.fileSystemIconService = fileSystemIconService;
                this.contextService = contextService;
                this.hotkey = "f";
                this.hotkeyDescription = "Search Files";
                this.context = "FileSearchService";
                this.contextSearch = this.context + "-Search";
                this.contextResults = this.context + "-Results";
                this.contextAvailable = this.context + "-Available";
                this.name = "File Search";
                this.icon = "search";
                this.noresults = "No matching files";
                this.results = [];
                this.doSearch = function (search) {
                    var qd = _this.$q.defer();
                    search.path = search.path || '/';
                    if (!search.search) {
                        qd.resolve([]);
                    }
                    else {
                        _this.$http.post('/api/search', search).then(qd.resolve, qd.reject);
                    }
                    return Rx.Observable.fromPromise(qd.promise);
                };
                this.contextService
                    .getContext("selectedFilesystemObject")
                    .subscribe(function (fso) {
                    _this.currentDirectory = fso.type == 'File' ? fso.parent : fso;
                });
                this.contextService
                    .getContext(this.contextSearch)
                    .debounce(100)
                    .map(function (s) {
                    return { search: s || "", path: _this.currentDirectory ? _this.currentDirectory.fullname : '' };
                })
                    .distinctUntilChanged()
                    .flatMapLatest(this.doSearch)
                    .subscribe(function (result) {
                    _this.results = result.data || [];
                    var searchResults = [];
                    var _loop_1 = function(r) {
                        searchResults.push({
                            html: _this.renderItem(r),
                            action: function () {
                                if (r.type == 'File')
                                    _this.directoryService.goFile(r);
                                else
                                    _this.directoryService.goDirectory(r);
                            }
                        });
                    };
                    for (var _i = 0, _a = _this.results; _i < _a.length; _i++) {
                        var r = _a[_i];
                        _loop_1(r);
                    }
                    contextService.onNextContext(_this.contextResults, searchResults);
                });
                this.contextService.onNextContext(this.context, this);
                this.contextService.onNextContext(this.contextAvailable, true);
            }
            FileSearchService.prototype.renderItem = function (item) {
                return '<div class="md-list-item-text">'
                    + '<i class="fa ' + this.fileSystemIconService.getIcon(item) + '"></i> '
                    + '<a class="md-list-item-text" >' + item.fullname + '</a>'
                    + '<span class="fileDate"><br>' + moment(item.modified).fromNow() + '</span>'
                    + '</div>';
            };
            return FileSearchService;
        }());
        var CustomerSearchService = (function () {
            function CustomerSearchService(directoryService, contextService) {
                var _this = this;
                this.directoryService = directoryService;
                this.contextService = contextService;
                this.context = "CustomerSearchService";
                this.contextSearch = this.context + "-Search";
                this.contextResults = this.context + "-Results";
                this.contextAvailable = this.context + "-Available";
                this.name = "Customer Search";
                this.icon = "supervisor_account";
                this.noresults = "No matching customers";
                this.hotkey = "c";
                this.hotkeyDescription = "Search Customers";
                this.customers = [];
                this.results = [];
                this.selectedItemChanged = function (customer) {
                    if (customer)
                        _this.directoryService.goPath('/sites/' + customer.code);
                };
                this.doSearch = function (search) {
                    if (!search)
                        return _this.customers;
                    var regex = new RegExp(search.replace(' ', '.*').replace('*', '.*'), 'ig');
                    function matchCustomer(customer) {
                        return regex.test(customer.code + customer.name);
                    }
                    return _.filter(_this.customers, matchCustomer);
                };
                var file = {
                    name: 'siteCodes.json',
                    fullname: '/siteCodes.json',
                    exists: true,
                    extension: '.json',
                    type: 'File'
                };
                directoryService.loadFile(file).then(function (fileContent) {
                    try {
                        var siteCodes = (typeof fileContent.body == "object" ? fileContent.body : JSON.parse(fileContent.body));
                        var customers = [];
                        for (var _i = 0, _a = _.filter(siteCodes, { IsActive: true }); _i < _a.length; _i++) {
                            var s = _a[_i];
                            customers.push({
                                code: s.Code,
                                name: s.CustomerName
                            });
                        }
                        _this.customers = _.sortBy(customers, ['name']);
                        contextService.onNextContext(_this.contextAvailable, true);
                    }
                    catch (exception) {
                        contextService.onNextContext(_this.contextAvailable, false);
                    }
                }, function (e) {
                    console.log("failed to get siteCodes.json, customer search unavailable");
                    contextService.onNextContext(_this.contextAvailable, false);
                });
                this.contextService
                    .getContext(this.contextSearch)
                    .debounce(100)
                    .distinctUntilChanged()
                    .subscribe(function (search) {
                    var searchResults = [];
                    var _loop_2 = function(customer) {
                        searchResults.push({
                            html: _this.renderItem(customer),
                            action: function () {
                                _this.directoryService.goPath('/sites/' + customer.code);
                            }
                        });
                    };
                    for (var _i = 0, _a = _this.doSearch(search); _i < _a.length; _i++) {
                        var customer = _a[_i];
                        _loop_2(customer);
                    }
                    contextService.onNextContext(_this.contextResults, searchResults);
                });
                this.contextService.onNextContext(this.context, this);
            }
            CustomerSearchService.prototype.renderItem = function (item) {
                return '<div class="md-list-item-text">'
                    + '<i class="fa fa-users"></i> '
                    + '<a class="md-list-item-text" >' + item.code + ': ' + item.name + '</a>'
                    + '</div>';
            };
            return CustomerSearchService;
        }());
        var FileSearchDirective = (function () {
            function FileSearchDirective() {
                this.templateUrl = '/public/app/dp/dp-filesearch.html';
                this.controllerAs = 'vm';
                this.controller = 'app.dp.FileSearchController';
                this.restrict = 'E';
                this.scope = {
                    search: '=search',
                    results: '=results',
                    displaySearch: '@displayResults',
                    displayResults: '@displayResults',
                };
            }
            FileSearchDirective.prototype.compile = function (element, attrs) {
                if (!attrs.displaySearch) {
                    attrs.displaySearch = true;
                }
                if (!attrs.displayResults) {
                    attrs.displayResults = true;
                }
            };
            return FileSearchDirective;
        }());
        var slice = [].slice;
        function buildDirectiveFactory(ctor) {
            var factory;
            factory = function () {
                var args;
                args = 1 <= arguments.length ? slice.call(arguments, 0) : [];
                return (function (func, args, ctor) {
                    ctor.prototype = func.prototype;
                    var child = new ctor, result = func.apply(child, args);
                    return Object(result) === result ? result : child;
                })(ctor, args, function () { });
            };
            factory.$inject = ctor.$inject;
            return factory;
        }
        ;
        angular
            .module('app.dp')
            .directive('dpFileSearch', buildDirectiveFactory(FileSearchDirective))
            .service('app.dp.FileSearchService', ['$q', '$http', 'app.giteditor.IGitDirectoryService', 'app.giteditor.IFileSystemIconService', 'app.context.IContextService', 'observeOnScope', FileSearchService])
            .service('app.dp.CustomerSearchService', ['app.giteditor.IGitDirectoryService', 'app.context.IContextService', CustomerSearchService])
            .controller('app.dp.FileSearchController', FileSearchController);
    })(dp = app.dp || (app.dp = {}));
})(app || (app = {}));
