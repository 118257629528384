var app;
(function (app) {
    var giteditor;
    (function (giteditor) {
        var HtmlRenderer = (function () {
            function HtmlRenderer($q, $sce) {
                this.$q = $q;
                this.$sce = $sce;
                this.extensions = ['.html'];
                this.requiresContent = true;
            }
            HtmlRenderer.prototype.render = function (content) {
                return {
                    isAsync: false,
                    immidate: this.$sce.getTrustedHtml(content.body),
                    eventual: this.$q.reject(false)
                };
            };
            HtmlRenderer.prototype.renderFile = function (file) { throw new Error("Not implemented"); };
            return HtmlRenderer;
        }());
        giteditor.HtmlRenderer = HtmlRenderer;
        function factory($q, $sce) {
            return new HtmlRenderer($q, $sce);
        }
        angular
            .module('app.giteditor')
            .service('app.giteditor.renderersHtmlRenderer', ['$q', '$sce', factory]);
    })(giteditor = app.giteditor || (app.giteditor = {}));
})(app || (app = {}));
