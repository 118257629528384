var app;
(function (app) {
    var dp;
    (function (dp) {
        'use strict';
        var SiteExporterService = (function () {
            function SiteExporterService($http, $q) {
                var _this = this;
                this.$http = $http;
                this.$q = $q;
                this.computerMap = {};
                this.computersJsons = [];
                this.hostsJsons = [];
                this.getSiteCodes = function () {
                    var promises = [];
                    var df = _this.$q.defer();
                    $.getJSON('/api/git/search/sites/*/data/hyperv*.json').then(function (data) {
                        _this.hostsJsons = data.map(function (c) { return c.fullname; });
                        promises.push(_this.$http.get('/siteCodes.json').then(function (transport) {
                            var sites = [];
                            angular.forEach(transport.data, function (code) {
                                if (!code.IsActive)
                                    return;
                                var node = { name: code.Code + ": " + code.CustomerName, items: [] };
                                promises.push((function (node, code) {
                                    var qf = _this.$q.defer();
                                    _this.getHosts(code).then(function (hosts) {
                                        node.items = hosts;
                                        qf.resolve(hosts);
                                    });
                                    return qf.promise;
                                })(node, code.Code));
                                sites.push(node);
                            });
                            df.resolve({ promises: promises, sites: sites });
                        }));
                    });
                    return df.promise;
                };
                this.getHosts = function (siteCode) {
                    var regEx = new RegExp('/sites/' + siteCode + '/data\/');
                    var urls = _.filter(_this.hostsJsons, function (u) { return u.match(regEx); });
                    if (!urls)
                        return _this.$q([]);
                    var promises = [];
                    var hosts = [];
                    var df = _this.$q.defer();
                    for (var _i = 0, urls_1 = urls; _i < urls_1.length; _i++) {
                        var url = urls_1[_i];
                        promises.push(_this.$http.get(url).then(function (transport) {
                            var host = transport.data;
                            var vms = host.VMs.map(function (vm) {
                                var intergration = vm.VMIntegrationServices.map(function (i) { return ({
                                    name: i.Name,
                                    enabled: i.Enabled
                                }); });
                                var disks = vm.HardDrives.map(function (hd) {
                                    var vhd = _.find(host.Vhds, function (v) { return (v.Path && v.Path.toLowerCase() === hd.Path.toLowerCase()); });
                                    if (!vhd)
                                        return { name: hd.Name };
                                    return {
                                        name: hd.Name,
                                        type: vhd.VhdType,
                                        attached: vhd.Attached,
                                        size: vhd.Size,
                                        minSize: vhd.MinimumSize,
                                        parentFreeSpace: vhd.ParentFreeSpace
                                    };
                                });
                                return {
                                    name: vm.VMName,
                                    generation: vm.Generation,
                                    status: vm.PrimaryStatusDescription,
                                    version: vm.Version,
                                    replicationState: vm.ReplicationState,
                                    replicationHealth: vm.ReplicationHealth,
                                    replicationMode: vm.ReplicationMode,
                                    cPUUsage: vm.CPUUsage,
                                    memoryAssigned: vm.MemoryAssigned,
                                    memoryDemand: vm.MemoryDemand,
                                    memoryStatus: vm.MemoryStatus,
                                    memoryStartup: vm.MemoryStartup,
                                    dynamicMemoryEnabled: vm.DynamicMemoryEnabled,
                                    memoryMinimum: vm.MemoryMinimum,
                                    memoryMaximum: vm.MemoryMaximum,
                                    intergrationServices: intergration,
                                    disks: disks
                                };
                            });
                            return {
                                type: 'host',
                                name: host.ComputerName,
                                items: vms
                            };
                        }));
                    }
                    _this.$q.all(promises).then(function (hosts) {
                        df.resolve(hosts);
                    });
                    return df.promise;
                };
            }
            SiteExporterService.prototype.buildMap = function () {
                var _this = this;
                var qd = this.$q.defer();
                this.getSiteCodes().then(function (state) {
                    _this.$q.all(state.promises).then(function (argsx) {
                        localStorage.setItem('siteCodeTree', JSON.stringify(state.sites));
                        qd.resolve(state.sites);
                    });
                });
                return qd.promise;
            };
            SiteExporterService.prototype.load = function () {
                if (localStorage && localStorage.getItem('siteCodeTree')) {
                    return JSON.parse(localStorage.getItem('siteCodeTree'));
                }
                return [];
            };
            return SiteExporterService;
        }());
        var SiteExplorerController = (function () {
            function SiteExplorerController($scope, siteExporterService) {
                var _this = this;
                this.$scope = $scope;
                this.siteExporterService = siteExporterService;
                this.sites = [];
                this.filtered = [];
                this.searchTerm = "";
                this.selectedItem = null;
                this.loading = true;
                this.filter = function (collection, term) {
                    if (!term) {
                        return collection;
                    }
                    if ($.isArray(collection)) {
                        return _.filter(collection, function (item) {
                            return _this.filter(item, term);
                        });
                    }
                    if (collection.name.match(term)) {
                        console.log("Match:::" + term + " ~+ " + collection.name);
                        return collection;
                    }
                    if (!$.isArray(collection.items))
                        return;
                    var filtered = [];
                    for (var _i = 0, _a = collection.items; _i < _a.length; _i++) {
                        var item = _a[_i];
                        if (item.name.match(term)) {
                            console.log("Match..." + term + " ~+ " + collection.name);
                            filtered.push(item);
                        }
                        else {
                            if ($.isArray(item.items)) {
                                var filteredItems = _.filter(item.items, function (i) {
                                    return _this.filter(i, term);
                                });
                                item.items = filteredItems;
                                filtered.push(item);
                            }
                        }
                    }
                    if (filtered.length === 0)
                        return;
                    collection.items = filtered;
                    return collection;
                };
                this.sites = siteExporterService.load();
                this.filtered = this.sites;
                siteExporterService.buildMap().then(function (sites) {
                    _this.sites = sites;
                    _this.filtered = sites;
                });
                var unsub = $scope.$watch(function () { return _this.searchTerm; }, function (newValue) {
                    _this.filtered = _this.filter(_this.sites, newValue);
                });
                $scope.$on("$destroy", unsub);
            }
            SiteExplorerController.$inject = ['$scope', 'app.dp.dpSiteExplorerService'];
            return SiteExplorerController;
        }());
        var SiteExplorerDirective = (function () {
            function SiteExplorerDirective() {
                this.controllerAs = 'vm';
                this.controller = 'app.dp.SiteExplorerController';
                this.templateUrl = 'app/dp/site-explorer.html';
                this.restrict = 'E';
                this.scope = {};
            }
            SiteExplorerDirective.$inject = [];
            return SiteExplorerDirective;
        }());
        var SiteExplorerDrillController = (function () {
            function SiteExplorerDrillController($scope) {
                var _this = this;
                this.$scope = $scope;
                this.getProperties = function (obj, padding) {
                    if (padding === void 0) { padding = ''; }
                    if (!obj || typeof obj !== 'object')
                        return;
                    var ret = [];
                    for (var _i = 0, _a = Object.keys(obj); _i < _a.length; _i++) {
                        var key = _a[_i];
                        if (key === '$$hashKey' || key === 'items' || !obj.hasOwnProperty(key))
                            continue;
                        if ($.isArray(obj[key])) {
                            ret.push({ key: key, value: _this.getArrayProperties(key, obj[key]) });
                        }
                        else {
                            ret.push({ key: key, value: obj[key] });
                        }
                    }
                    return ret.map(function (kvp) { return (kvp.key + ': ' + kvp.value); }).join("\n" + padding);
                };
                this.getArrayProperties = function (label, array) {
                    if (!$.isArray(array))
                        return 'N/A';
                    var ret = [];
                    for (var key in array) {
                        ret.push("\n  [" + key + "] " + _this.getProperties(array[key], "      "));
                    }
                    return ret.join("\n");
                };
            }
            SiteExplorerDrillController.$inject = ['$scope'];
            return SiteExplorerDrillController;
        }());
        var SiteExplorerDirectiveDrill = (function () {
            function SiteExplorerDirectiveDrill() {
                this.controller = 'app.dp.SiteExplorerDrillController';
                this.controllerAs = 'ctrl';
                this.templateUrl = 'app/dp/site-explorer-drill.html';
                this.restrict = 'E';
                this.scope = {
                    drill: '=item',
                    vm: '='
                };
            }
            SiteExplorerDirectiveDrill.$inject = [];
            return SiteExplorerDirectiveDrill;
        }());
        var slice = [].slice;
        function buildDirectiveFactory(ctor) {
            var factory;
            factory = function () {
                var args;
                args = 1 <= arguments.length ? slice.call(arguments, 0) : [];
                return (function (func, args, ctor) {
                    ctor.prototype = func.prototype;
                    var child = new ctor, result = func.apply(child, args);
                    return Object(result) === result ? result : child;
                })(ctor, args, function () { });
            };
            factory.$inject = ctor.$inject;
            return factory;
        }
        ;
        angular
            .module('app.dp')
            .directive('dpSiteExplorer', buildDirectiveFactory(SiteExplorerDirective))
            .directive('dpSiteExplorerDrill', buildDirectiveFactory(SiteExplorerDirectiveDrill))
            .service('app.dp.dpSiteExplorerService', ['$http', '$q', SiteExporterService])
            .controller('app.dp.SiteExplorerController', SiteExplorerController)
            .controller('app.dp.SiteExplorerDrillController', SiteExplorerDrillController);
    })(dp = app.dp || (app.dp = {}));
})(app || (app = {}));
