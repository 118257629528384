var app;
(function (app) {
    var giteditor;
    (function (giteditor) {
        'use strict';
        ;
        var GitRenderService = (function () {
            function GitRenderService($q, $injector) {
                this.$q = $q;
                this.$injector = $injector;
            }
            GitRenderService.prototype.canRender = function (meta) {
                return GitRenderService.renderers.hasOwnProperty(meta.extension.toLowerCase());
            };
            GitRenderService.prototype.registerRenderer = function (extension, renderer) {
                GitRenderService.ensureRenderersLoaded(this.$injector);
                GitRenderService.renderers[extension.toLowerCase()] = renderer;
            };
            GitRenderService.prototype.render = function (content) {
                GitRenderService.ensureRenderersLoaded(this.$injector);
                if (this.canRender(content.metadata)) {
                    return GitRenderService.renderers[(content.metadata.extension || "").toLowerCase()].render(content);
                }
                return {
                    isAsync: false,
                    immidate: '<br><br><br><p>We cannot currently render ' + content.metadata.extension + ' files. To edit or view this file use the download button.</p>',
                    eventual: this.$q.reject(false)
                };
            };
            GitRenderService.prototype.renderFile = function (file) {
                GitRenderService.ensureRenderersLoaded(this.$injector);
                if (this.canRender(file)) {
                    return GitRenderService.renderers[(file.extension || "").toLowerCase()].renderFile(file);
                }
                return {
                    isAsync: false,
                    immidate: '<br><br><br><p>We cannot currently render ' + file.extension + ' files. To edit or view this file use the download button.</p>',
                    eventual: this.$q.reject(false)
                };
            };
            GitRenderService.prototype.getRenderer = function (file) {
                GitRenderService.ensureRenderersLoaded(this.$injector);
                if (this.canRender(file)) {
                    return GitRenderService.renderers[(file.extension || "").toLowerCase()];
                }
                return GitRenderService.defaultRenderer;
            };
            GitRenderService.ensureRenderersLoaded = function ($injector) {
                if (!GitRenderService.renderersLoaded) {
                    GitRenderService.renderersLoaded = true;
                    var injectable = [];
                    angular.module('app.giteditor')['_invokeQueue'].forEach(function (value) { injectable.push(value[2][0]); });
                    var regex = new RegExp("^app.giteditor.renderers");
                    for (var _i = 0, injectable_1 = injectable; _i < injectable_1.length; _i++) {
                        var serviceName = injectable_1[_i];
                        if (serviceName.match(regex)) {
                            var service = $injector.get(serviceName);
                            GitRenderService.renderers[serviceName] = service;
                            for (var _a = 0, _b = service.extensions; _a < _b.length; _a++) {
                                var ext = _b[_a];
                                GitRenderService.renderers[ext] = service;
                            }
                            if (serviceName === 'app.giteditor.renderersNullRenderer') {
                                GitRenderService.defaultRenderer = service;
                            }
                        }
                    }
                }
            };
            GitRenderService.defaultRenderer = null;
            GitRenderService.renderers = {};
            GitRenderService.renderersLoaded = false;
            return GitRenderService;
        }());
        giteditor.GitRenderService = GitRenderService;
        factory.$inject = [];
        function factory($q, $injector) {
            return new GitRenderService($q, $injector);
        }
        angular
            .module('app.giteditor')
            .service('app.giteditor.GitRenderService', ['$q', '$injector', factory]);
    })(giteditor = app.giteditor || (app.giteditor = {}));
})(app || (app = {}));
