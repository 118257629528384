var app;
(function (app) {
    var giteditor;
    (function (giteditor) {
        'use strict';
        function sortDirectory(dirA, dirB) {
            if (dirA.name.toUpperCase() < dirB.name.toUpperCase())
                return -1;
            if (dirA.name.toUpperCase() > dirB.name.toUpperCase())
                return 1;
            return 0;
        }
        var GitDirectoryService = (function () {
            function GitDirectoryService($q, $http, $timeout, $state, serverRoutes, cacheFactory, contextService) {
                var _this = this;
                this.$q = $q;
                this.$http = $http;
                this.$timeout = $timeout;
                this.$state = $state;
                this.serverRoutes = serverRoutes;
                this.cacheFactory = cacheFactory;
                this.contextService = contextService;
                this.currentDirectory = null;
                this.currentFile = null;
                this.error = function (error) {
                    console.error(error);
                };
                this.loadRoot = function () {
                    _this.log("load root");
                    return _this.loadDirectory({
                        type: 'Directory',
                        exists: true,
                        path: "",
                        parent: null,
                        name: "",
                        fullname: "",
                        content: []
                    });
                };
                this.selectedFilesystemObjectChanged = function (selectedFilesystemObject) {
                    _this.log('raising next selectedFilesystemObject: ', selectedFilesystemObject.fullname, selectedFilesystemObject.parent);
                    _this.contextService.onNextContext('selectedFilesystemObject', selectedFilesystemObject);
                };
                this.loadDirectory = function (directory) {
                    _this.log("loadDirectory: " + directory.fullname);
                    if (!directory)
                        throw "loadDirectory requires a Directory";
                    if (directory.type !== 'Directory') {
                        var path = directory.fullname.substr(0, directory.fullname.lastIndexOf('/'));
                        var dir = _this.findDirectory(path);
                        if (dir) {
                            _this.selectedFilesystemObjectChanged(dir);
                            return Q(dir);
                        }
                        var promise = _this.loadTree(path);
                        promise.then(_this.selectedFilesystemObjectChanged);
                        return promise;
                    }
                    var d = _this.findDirectory(directory.fullname);
                    if (d && d.fullname.replace(/^[/]*/, '') === directory.fullname.replace(/^[/]*/, '')) {
                        if (d.content) {
                            _this.selectedFilesystemObjectChanged(d);
                            return Q(d);
                        }
                        else {
                            var promise = _this.loadTree(directory.fullname);
                            promise.then(_this.selectedFilesystemObjectChanged);
                            return promise;
                        }
                    }
                    var qd = _this.$q.defer();
                    qd.promise.then(_this.selectedFilesystemObjectChanged);
                    _this.$http.get(_this.serverRoutes.git + "directory/" + encodeURIComponent(directory.fullname).replace('%2F', '/'))
                        .then(function (transport) {
                        var directories = [];
                        var files = [];
                        if (transport.data) {
                            for (var _i = 0, _a = transport.data; _i < _a.length; _i++) {
                                var d_1 = _a[_i];
                                if (d_1.type === 'Directory') {
                                    d_1.parent = directory;
                                    directories.push(d_1);
                                }
                                if (d_1.type === 'File') {
                                    d_1.parent = directory;
                                    files.push(d_1);
                                }
                            }
                        }
                        directory.content = directories.concat(files);
                        if (!_this.root)
                            _this.root = directory;
                        qd.resolve(directory);
                    })
                        .catch(function (e) {
                        qd.reject(e);
                        _this.error(e);
                    });
                    return qd.promise;
                };
                this.loadTree = function (directoryPath) {
                    _this.log("loadTree: " + directoryPath);
                    directoryPath = directoryPath || '/';
                    var qd = _this.$q.defer();
                    qd.promise.then(_this.selectedFilesystemObjectChanged);
                    if (directoryPath != '/')
                        directoryPath = directoryPath.replace(/\/*$/, '');
                    _this.$http.get(_this.serverRoutes.git + "tree/" + encodeURIComponent(directoryPath).replace('%2F', '/'))
                        .then(function (transport) {
                        if (!transport.data) {
                            qd.reject("Path not found");
                            return;
                        }
                        var directories = [];
                        var files = [];
                        var directory = {
                            fullname: transport.data.fullname,
                            name: transport.data.name,
                            parent: transport.data.parent,
                            exists: transport.data.exists,
                            type: 'Directory',
                            content: []
                        };
                        for (var _i = 0, _a = (transport.data.content || []).sort(sortDirectory); _i < _a.length; _i++) {
                            var d = _a[_i];
                            if (d.type === 'Directory') {
                                d.parent = directory;
                                d.content = null;
                                directories.push(d);
                            }
                            if (d.type === 'File') {
                                d.parent = directory;
                                files.push(d);
                            }
                        }
                        directory.content = directories.concat(files);
                        var root = directory;
                        while (root.parent) {
                            root = root.parent;
                        }
                        for (var _b = 0, _c = root.content; _b < _c.length; _b++) {
                            var r = _c[_b];
                            r.parent = root;
                        }
                        _this.root = root;
                        if (directoryPath.replace(/^[/]*/, '') === (directory.fullname || '').replace(/^[/]*/, '')) {
                            _this.currentDirectory = directory;
                            return qd.resolve(directory);
                        }
                        for (var _d = 0, files_1 = files; _d < files_1.length; _d++) {
                            var f = files_1[_d];
                            if (directoryPath.replace(/^[/]*/, '') === (f.fullname || '').replace(/^[/]*/, '')) {
                                _this.currentFile = f;
                                return qd.resolve(f);
                            }
                        }
                        qd.reject('fall though (aka full name did not match a directory or file)');
                    })
                        .catch(function (e) {
                        qd.reject(e);
                        _this.error(e);
                    });
                    return qd.promise;
                };
                this.loadFile = function (file) {
                    _this.log("loadFile: " + file.fullname);
                    if (!file)
                        return null;
                    var qd = _this.$q.defer();
                    _this.$http.get(_this.getFileUrl(file), {
                        cache: _this.cache('file-content'),
                        transformResponse: [function (data) { return data; }]
                    })
                        .then(function (transport) {
                        var content = {
                            metadata: file,
                            body: transport.data
                        };
                        qd.resolve(content);
                    })
                        .catch(function (e) {
                        qd.reject(e);
                        _this.error(e);
                    });
                    return qd.promise;
                };
                this.commitFile = function (commitMessage, files) {
                    _this.log("saveFile: " + commitMessage, files);
                    var qd = _this.$q.defer();
                    var form = new FormData();
                    form.append('CommitMessage', commitMessage);
                    for (var i = 0; i < files.length; i++) {
                        form.append('file-' + i, new File([files[i].body], files[i].metadata.fullname));
                    }
                    _this.$http.post(_this.serverRoutes.git + 'commit', form, {
                        transformRequest: angular.identity,
                        headers: { 'Content-Type': undefined }
                    })
                        .then(function (transport) {
                        for (var _i = 0, files_2 = files; _i < files_2.length; _i++) {
                            var f = files_2[_i];
                            _this.invalidateTree(f.metadata.fullname.substr(0, f.metadata.fullname.lastIndexOf('/')));
                        }
                        if (transport.status >= 200 && transport.status <= 299) {
                            qd.resolve(true);
                        }
                        else {
                            qd.reject(transport.statusText);
                        }
                    })
                        .catch(function (e) {
                        qd.reject(e);
                        _this.error(e);
                    });
                    return qd.promise;
                };
                this.saveFile = function (commitMessage, files) {
                    _this.log("saveFile: " + commitMessage, files);
                    var qd = _this.$q.defer();
                    var form = new FormData();
                    form.append('CommitMessage', commitMessage);
                    for (var i = 0; i < files.length; i++) {
                        form.append('file-' + i, new File([new Uint8Array(files[i].file)], files[i].metadata.fullname));
                    }
                    _this.$http.post(_this.serverRoutes.git + 'commit', form, {
                        transformRequest: angular.identity,
                        headers: { 'Content-Type': undefined }
                    })
                        .then(function (transport) {
                        for (var _i = 0, files_3 = files; _i < files_3.length; _i++) {
                            var f = files_3[_i];
                            _this.invalidateTree(f.metadata.fullname.substr(0, f.metadata.fullname.lastIndexOf('/')));
                        }
                        if (transport.status >= 200 && transport.status <= 299) {
                            qd.resolve(true);
                        }
                        else {
                            qd.reject(transport.statusText);
                        }
                    })
                        .catch(function (e) {
                        qd.reject(e);
                        _this.error(e);
                    });
                    return qd.promise;
                };
                this.saveSimpleFile = function (path, content) {
                    var qd = _this.$q.defer();
                    _this.$http.post(_this.serverRoutes.git + 'file/' + path, content.body, {
                        transformRequest: angular.identity,
                        headers: { 'Content-Type': undefined }
                    })
                        .then(function (transport) {
                        _this.invalidateTree(path);
                        if (transport.status >= 200 && transport.status <= 299) {
                            qd.resolve(true);
                        }
                        else {
                            qd.reject(transport.statusText);
                        }
                    })
                        .catch(function (e) {
                        qd.reject(e);
                        _this.error(e);
                    });
                    return qd.promise;
                };
                this.loadPath = function (path) {
                    _this.log('Loadpath: ' + path);
                    var dir = _this.findDirectory(path);
                    if (dir) {
                        if (dir.fullname === path) {
                            return _this.$q.when(dir);
                        }
                        else {
                            var file = _.find(dir.content, { fullname: path });
                            if (file) {
                                return _this.$q.when(file);
                            }
                        }
                    }
                    var qd = _this.$q.defer();
                    _this.loadTree(path).then(function (dir) {
                        qd.resolve(dir);
                    }, function (error) { qd.reject(error); });
                    return qd.promise;
                };
                this.goDirectory = function (directory) {
                    _this.log('goDirectory: ' + directory.fullname);
                    var fromDirectory = _this.currentDirectory;
                    var fromFile = _this.currentFile;
                    _this.currentFile = null;
                    _this.currentDirectory = directory;
                    _this.$state.get('browse-directory').data.currentDirectory = directory;
                    _this.$state.go('browse-directory', { path: directory.fullname }).then(function () {
                        _this.selectedFilesystemObjectChanged(directory);
                    }, function () {
                        _this.currentDirectory = fromDirectory;
                        _this.currentFile = fromFile;
                    });
                };
                this.goFile = function (file) {
                    _this.log('goFile: ' + file.fullname);
                    var fromDirectory = _this.currentDirectory;
                    var fromFile = _this.currentFile;
                    _this.currentFile = file;
                    _this.$state.get('browse-directory').data.currentDirectory = file;
                    _this.$state.go('browse-directory', { path: file.fullname }).then(function () {
                        _this.selectedFilesystemObjectChanged(file);
                    }, function () {
                        _this.currentDirectory = fromDirectory;
                        _this.currentFile = fromFile;
                    });
                };
                this.goPath = function (path) {
                    _this.log('goPath: ' + path);
                    path = (path || "").toLowerCase();
                    var dir = _this.findDirectory(path);
                    if (dir && dir.fullname.toLowerCase().replace(/[/]+/ig, '/') === path.replace(/[/]+/ig, '/')) {
                        _this.goDirectory(dir);
                        return;
                    }
                    _this.loadTree(path).then(function (dir) {
                        _this.goDirectory(dir);
                    });
                };
                this.goUp = function () {
                    _this.log('goUp');
                    var sel = null;
                    if (_this.currentFile) {
                        sel = _this.currentFile;
                    }
                    else {
                        sel = _this.currentDirectory;
                    }
                    if (sel && sel.parent) {
                        _this.goDirectory(sel.parent);
                        return;
                    }
                    if (location.pathname.match('/browse/[^/]*')) {
                        location.pathname = location.pathname.substr(0, location.pathname.lastIndexOf('/'));
                    }
                    else {
                        location.reload();
                    }
                };
                this.goEditFile = function (file) {
                    _this.log('goEditFile: ' + file.fullname);
                    _this.currentFile = file;
                    _this.$state.get('edit-file').data.currentFile = file;
                    _this.$state.go('edit-file', { path: ('/' + file.fullname).replace(/\/+/, '/') });
                };
                this.invalidateTree = function (path) {
                    _this.log('invalidateTree: ' + path);
                    var directory = _this.findDirectory(path);
                    if (!directory) {
                        console.warn('failed to invalidate tree using path ' + path);
                        return;
                    }
                    if (!directory.content && directory.parent) {
                        directory = directory.parent;
                    }
                    if (directory && directory.content) {
                        for (var _i = 0, _a = directory.content; _i < _a.length; _i++) {
                            var fs = _a[_i];
                            _this.uncache(_this.getFileUrl(fs));
                        }
                    }
                    var cache = _this.cache('file-content');
                    var cacheKeys = cache.keys();
                    if (cacheKeys) {
                        var regex = new RegExp("^" + path);
                        for (var _b = 0, cacheKeys_1 = cacheKeys; _b < cacheKeys_1.length; _b++) {
                            var k = cacheKeys_1[_b];
                            if (k.toLowerCase().match(regex)) {
                                cache.remove(k);
                            }
                        }
                    }
                    if (directory)
                        directory.content = null;
                };
                this.clearCache = function () {
                    _this.log("clearCache");
                    var cache = _this.cache('file-content');
                    for (var _i = 0, _a = cache.keys(); _i < _a.length; _i++) {
                        var k = _a[_i];
                        cache.remove(k);
                    }
                };
                this.delete = function (file) {
                    _this.log("delete: " + file.fullname);
                    var qd = _this.$q.defer();
                    _this.$http.delete(_this.getFileUrl(file)).then(function () {
                        if (file.parent) {
                            _this.invalidateTree(file.parent.fullname);
                        }
                        else {
                            _this.invalidateTree(file.fullname);
                        }
                        qd.resolve({});
                    }, function (error) {
                        qd.reject(error);
                    });
                    return qd.promise;
                };
                this.uncache = function (key) {
                    _this.log("clearCache");
                    if (!key) {
                        console.log('uncache called without a key... bug?');
                        return '';
                    }
                    _this.cache('file-content').remove(key);
                };
                this.cache = function (key) {
                    _this.log("cache: " + key);
                    if (!_this.cacheFactory.get(key))
                        _this.cacheFactory(key, {
                            maxAge: 15,
                            cacheFlushInterval: 60,
                            deleteOnExpire: 'aggressive',
                            storageMode: 'memory'
                        });
                    return _this.cacheFactory.get(key);
                };
            }
            GitDirectoryService.prototype.log = function () {
                var argArry = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    argArry[_i - 0] = arguments[_i];
                }
                return;
            };
            GitDirectoryService.prototype.getFileUrl = function (file) {
                this.log("getFileUrl: " + file.fullname);
                if (!file) {
                    console.log('getFileUrl called without a file... bug?');
                    return '';
                }
                return this.serverRoutes.git + "file/" + encodeURIComponent(file.fullname).replace('%2F', '/').replace(/^\/+/, '');
            };
            GitDirectoryService.prototype.findDirectory = function (path) {
                this.log("getFileUrl: " + path);
                path = path.toLowerCase();
                if (this.currentDirectory && path == this.currentDirectory.fullname.toLowerCase())
                    return this.currentDirectory;
                if (path === '' || path === '/')
                    return this.root;
                var bits = path.split('/');
                var directory = this.root;
                for (var _i = 0, bits_1 = bits; _i < bits_1.length; _i++) {
                    var bit = bits_1[_i];
                    if (bit == '')
                        continue;
                    var nextDirectory = _.find(directory.content || [], function (dir) { return dir.name.toLowerCase().replace(/^[/]*/, '') === bit && dir.type === 'Directory'; });
                    if (nextDirectory) {
                        directory = nextDirectory;
                        continue;
                    }
                    break;
                }
                return directory;
            };
            return GitDirectoryService;
        }());
        var FileSystemIconService = (function () {
            function FileSystemIconService() {
            }
            FileSystemIconService.prototype.getIcon = function (fso) {
                if (fso.type == 'Directory') {
                    return 'fa-folder-o';
                }
                switch (fso.extension.toLowerCase()) {
                    case '.pdf': return 'fa-file-pdf-o';
                    case '.txt': return 'fa-file-text-o ';
                    case '.jpg': return 'fa-picture-o';
                    case '.png': return 'fa-file-image-o';
                    case '.zip':
                    case '.cab': return 'fa-file-archive-o ';
                    case '.html':
                    case '.htm': return 'fa-chrome';
                    case '.cer':
                    case '.pfx': return 'fa-certificate';
                    case '.md': return 'fa-file';
                    default: return 'fa-file-o';
                }
            };
            return FileSystemIconService;
        }());
        angular
            .module('app.giteditor')
            .service('app.giteditor.IGitDirectoryService', ['$q', '$http', '$timeout', '$state', 'IServerRoutes', 'CacheFactory', 'app.context.IContextService', GitDirectoryService])
            .service('app.giteditor.IFileSystemIconService', [FileSystemIconService]);
    })(giteditor = app.giteditor || (app.giteditor = {}));
})(app || (app = {}));
