var app;
(function (app) {
    var giteditor;
    (function (giteditor) {
        var SearchController = (function () {
            function SearchController($q, $scope, directoryService, contextService, observeOnScope, fileSearch, customerSearch, hotkeys) {
                var _this = this;
                this.$q = $q;
                this.directoryService = directoryService;
                this.contextService = contextService;
                this.searchOptions = [];
                this.qd = null;
                this.getResults = function () {
                    _this.qd = _this.$q.defer();
                    return _this.qd.promise;
                };
                this.setup = function () {
                    if (!_this.selectedSearchOption && _this.searchOptions.length > 0) {
                        _this.selectedSearchOption = _this.searchOptions[0];
                    }
                };
                this.select = function (item) {
                    _this.search = '';
                    if (item.action) {
                        item.action();
                    }
                    else {
                        console.log("debug: item action != function", item);
                    }
                };
                this.openMenu = function ($mdOpenMenu, ev) {
                    $mdOpenMenu(ev);
                };
                for (var _i = 0, _a = ['FileSearchService', 'CustomerSearchService']; _i < _a.length; _i++) {
                    var providerName = _a[_i];
                    contextService.getContext(providerName)
                        .subscribe(function (provider) {
                        _this.contextService.getContext(provider.contextAvailable).safeApply($scope, function (available) {
                            if (available) {
                                _this.searchOptions.push(provider);
                                hotkeys.bindTo($scope)
                                    .add({
                                    combo: provider.hotkey,
                                    description: provider.hotkeyDescription,
                                    callback: function () {
                                        event.preventDefault();
                                        _this.search = '';
                                        _this.selectedSearchOption = provider;
                                        angular.element('#search-input').val('').focus();
                                    }
                                });
                            }
                            else {
                                if (_this.searchOptions.indexOf(provider) !== -1) {
                                    _this.searchOptions.splice(_this.searchOptions.indexOf(provider), 1);
                                    hotkeys.del(provider.hotkey);
                                }
                            }
                            _this.setup();
                        }).subscribe();
                    });
                }
                var resultsSubscription = null;
                observeOnScope($scope, function () { return _this.selectedSearchOption; })
                    .safeApply($scope, function (obj) {
                    if (obj.oldValue && resultsSubscription)
                        _this.contextService.dispose(obj.oldValue.contextResults, resultsSubscription);
                    if (obj.newValue)
                        resultsSubscription = _this.contextService
                            .getContext(obj.newValue.contextResults)
                            .safeApply($scope, function (results) {
                            _this.results = results;
                            if (_this.qd)
                                _this.qd.resolve(results);
                        }).subscribe();
                }).subscribe();
                observeOnScope($scope, function () { return _this.search; })
                    .map(function (newAndOldValues) {
                    return { search: newAndOldValues.newValue, provider: _this.selectedSearchOption };
                })
                    .distinctUntilChanged()
                    .safeApply($scope, function (obj) {
                    if (obj.provider)
                        _this.contextService.onNextContext(obj.provider.contextSearch, obj.search);
                    else
                        console.log('No selected search provider... bug?');
                }).subscribe();
            }
            SearchController.$inject = ['$q', '$scope', 'app.giteditor.IGitDirectoryService', 'app.context.IContextService', 'observeOnScope', 'app.dp.FileSearchService', 'app.dp.CustomerSearchService', 'hotkeys'];
            return SearchController;
        }());
        var SearchDirective = (function () {
            function SearchDirective() {
                this.templateUrl = '/public/app/giteditor/search.html';
                this.controllerAs = 'vm';
                this.controller = 'app.giteditor.SearchController';
                this.restrict = 'E';
                this.scope = {};
            }
            return SearchDirective;
        }());
        var EnterDirective = (function () {
            function EnterDirective(scope, element, attrs) {
                element.bind("keydown keypress", function (event) {
                    if (event.which === 13) {
                        scope.$apply(function () {
                            scope.$eval(attrs.ngEnter, { 'event': event });
                        });
                        event.preventDefault();
                    }
                });
            }
            return EnterDirective;
        }());
        var slice = [].slice;
        function buildDirectiveFactory(ctor) {
            var factory = function () {
                var args = 1 <= arguments.length ? slice.call(arguments, 0) : [];
                return (function (func, args, ctor) {
                    ctor.prototype = func.prototype;
                    var child = new ctor, result = func.apply(child, args);
                    return Object(result) === result ? result : child;
                })(ctor, args, function () { });
            };
            factory.$inject = ctor.$inject;
            return factory;
        }
        ;
        angular
            .module('app.giteditor')
            .directive('dpSearch', buildDirectiveFactory(SearchDirective))
            .directive('enter', buildDirectiveFactory(EnterDirective))
            .controller('app.giteditor.SearchController', SearchController);
    })(giteditor = app.giteditor || (app.giteditor = {}));
})(app || (app = {}));
