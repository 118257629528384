var app;
(function (app) {
    var bpmm;
    (function (bpmm) {
        var BPMNViwerDirective = (function () {
            function BPMNViwerDirective($timeout, $templateRequest, $compile, gitRendererService, gitDirectoryService) {
                var _this = this;
                this.$timeout = $timeout;
                this.$templateRequest = $templateRequest;
                this.$compile = $compile;
                this.gitRendererService = gitRendererService;
                this.gitDirectoryService = gitDirectoryService;
                this.restrict = 'E';
                this.link = function (scope, element) {
                    console.log('link');
                    scope.$watch('path', function () {
                        _this.render(scope, element);
                    }, true);
                    scope.$on("$destroy", function handler() {
                        if (this.attached) {
                            this.attached = false;
                            this.viewer.detach();
                            this.viewer.destroy();
                        }
                    });
                    _this.render(scope, element);
                };
                this.renderPending = {};
                this.render = function (scope, element) {
                    if (_this.renderPending.hasOwnProperty(scope.$id))
                        _this.$timeout.cancel(_this.renderPending[scope.$id]);
                    _this.renderPending[scope.$id] = _this.$timeout(function () { _this._render(scope, element); }, 300);
                };
                this.attached = false;
                this.viewer = null;
                this.import = function (fileContent) {
                    if (!_this.attached) {
                        return;
                    }
                    _this.viewer.importXML(fileContent.body, function (err) {
                        if (err) {
                            console.log('error rendering', err);
                        }
                        else {
                            console.log('rendered');
                        }
                    });
                };
                this._render = function (scope, element) {
                    if (!scope.path) {
                        return;
                    }
                    if (!_this.attached) {
                        _this.attached = true;
                        _this.viewer = new BpmnJS();
                        _this.viewer.attachTo(element);
                        _this.gitDirectoryService
                            .loadFile({ type: 'File', exists: true, extension: '.bpmn', fullname: scope.path, name: 'render' })
                            .then(_this.import).catch(console.error);
                    }
                };
                this.scope = {
                    template: '=path'
                };
            }
            BPMNViwerDirective.$inject = ['$timeout', '$templateRequest', '$compile', 'app.giteditor.GitRenderService', 'app.giteditor.IGitDirectoryService'];
            return BPMNViwerDirective;
        }());
        var slice = [].slice;
        function buildDirectiveFactory(ctor) {
            var factory;
            factory = function () {
                var args;
                args = 1 <= arguments.length ? slice.call(arguments, 0) : [];
                return (function (func, args, ctor) {
                    ctor.prototype = func.prototype;
                    var child = new ctor, result = func.apply(child, args);
                    return Object(result) === result ? result : child;
                })(ctor, args, function () { });
            };
            factory.$inject = ctor.$inject;
            return factory;
        }
        ;
        angular
            .module('app.bpmn')
            .directive('bpmnViewer', buildDirectiveFactory(BPMNViwerDirective));
    })(bpmm = app.bpmm || (app.bpmm = {}));
})(app || (app = {}));
