(function () {
    'use strict';
    var trace = !!localStorage.getItem('trace');
    angular
        .module('app')
        .config(config);
    if (!String.prototype.startsWith) {
        String.prototype.startsWith = function (searchString, position) {
            position = position || 0;
            return this.substr(position, searchString.length) === searchString;
        };
    }
    var root = document.querySelector('html').getAttribute("root");
    angular.module('app').factory('middleware', ['$templateCache', function ($templateCache) {
            return {
                request: function (config) {
                    var url = config.url.replace('public/', '');
                    if (typeof ($templateCache.get(url)) !== 'undefined') {
                        if (trace)
                            console.debug('url transform: ' + url + " CACHED");
                        return config;
                    }
                    url = url.toLowerCase();
                    if (url.startsWith('/'))
                        url = url.slice(1);
                    if (url.startsWith('browse') || url.startsWith('app') || url.startsWith('lib')) {
                        if (trace)
                            console.debug('url transform: ' + url + " => " + config.url);
                        return config;
                    }
                    if (config.url[0] != '/')
                        config.url = '/' + config.url;
                    config.url = root + config.url;
                    if (trace)
                        console.debug('url transform: ' + url + " => " + config.url);
                    return config;
                }
            };
        }]);
    config.$inject = [
        '$locationProvider',
        '$httpProvider',
        '$mdThemingProvider'
    ];
    function config($locationProvider, $httpProvider, $mdThemingProvider) {
        $mdThemingProvider.theme('default')
            .primaryPalette('blue-grey')
            .accentPalette('deep-orange');
        if (localStorage.getItem("theme")) {
            var theme = JSON.parse(localStorage.getItem("theme"));
            if (theme.primaryPalette)
                $mdThemingProvider.theme('default').primaryPalette(theme.primaryPalette);
            if (theme.accentPalette)
                $mdThemingProvider.theme('default').accentPalette(theme.accentPalette);
            if (theme.dark)
                $mdThemingProvider.theme('default').dark();
        }
        $locationProvider.html5Mode(true);
        $httpProvider.interceptors.push(function () {
            return {
                responseError: function (rejection) {
                    if (trace)
                        debugger;
                    if (rejection.status !== 401) {
                        return rejection;
                    }
                    if (location.pathname !== '/browse/login')
                        localStorage.setItem('unauth-nexthop', location.pathname);
                    return rejection;
                }
            };
        });
        $httpProvider.interceptors.push('middleware');
    }
})();
