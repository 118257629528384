var app;
(function (app) {
    var giteditor;
    (function (giteditor) {
        'use strict';
        var BPMNEditorController = (function () {
            function BPMNEditorController($state, directoryService) {
                var _this = this;
                this.$state = $state;
                this.directoryService = directoryService;
                this.hello = "hello";
                this.setContent = function (content) {
                    debugger;
                    _this.fileContent = content;
                };
                this.view = "/public/app/giteditor/editors/bpmn.html";
                this.extensions = ['.bpmn'];
                this.file = this.$state.get('edit-file').data.currentFile;
                if (!this.file) {
                    return;
                }
                this.directoryService
                    .loadFile(this.file)
                    .then(this.setContent)
                    .catch(function (e) { return console.error("BPMN Editor Controller Error:", e); });
            }
            BPMNEditorController.$inject = ['$state', 'app.giteditor.IGitDirectoryService'];
            return BPMNEditorController;
        }());
        giteditor.BPMNEditorController = BPMNEditorController;
        angular
            .module('app.giteditor')
            .controller('app.giteditor.editorsBPMNEditorController', BPMNEditorController);
    })(giteditor = app.giteditor || (app.giteditor = {}));
})(app || (app = {}));
