var app;
(function (app) {
    var giteditor;
    (function (giteditor) {
        'use strict';
        var GiteditorController = (function () {
            function GiteditorController() {
            }
            GiteditorController.$inject = [];
            return GiteditorController;
        }());
        angular
            .module('app.giteditor')
            .controller('app.giteditor.GiteditorController', GiteditorController);
    })(giteditor = app.giteditor || (app.giteditor = {}));
})(app || (app = {}));
