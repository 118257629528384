var app;
(function (app) {
    var jira;
    (function (jira) {
        'use strict';
        var JiraService = (function () {
            function JiraService($q, $http) {
                var _this = this;
                this.$q = $q;
                this.$http = $http;
                this.basic = {};
                this.sessions = {};
                this.getOptions = function (serverAddress) {
                    return { headers: { 'Accept': 'application/json, text/plain, */*', 'Authorization': _this.basic[serverAddress] } };
                };
                this.getUrl = function (serverAddress, relUri) {
                    serverAddress = serverAddress || 'http://projects.dynamicplatform.com/';
                    return (serverAddress.lastIndexOf('/') == serverAddress.length)
                        ? serverAddress + relUri
                        : serverAddress + '/' + relUri;
                };
                var ss = sessionStorage.getItem('folio-jira-basic');
                if (ss) {
                    this.basic = JSON.parse(ss);
                }
            }
            JiraService.prototype.authorize = function (serverAddress, username, password) {
                this.basic[serverAddress] = "Basic " + btoa(username + ":" + password);
                sessionStorage.setItem('folio-jira-basic', JSON.stringify(this.basic));
                return this.$q.when(true);
            };
            JiraService.prototype.jqlQuery = function (serverAddress, jql, fields, max) {
                if (!this.basic.hasOwnProperty(serverAddress)) {
                    return this.$q.reject("Authentication required.");
                }
                var qd = this.$q.defer();
                this.$http.get(this.getUrl(serverAddress, 'rest/api/2/search?jql=' + encodeURIComponent(jql) + "&startAt=0&maxResults=" + max + "&fields=" + fields.join()), this.getOptions(serverAddress))
                    .then(function (transport) {
                    if (transport.status !== 200) {
                        qd.reject(transport.data);
                        return;
                    }
                    qd.resolve(transport.data);
                })
                    .catch(function (e) {
                    qd.reject(e);
                });
                return qd.promise;
            };
            return JiraService;
        }());
        var JiraController = (function () {
            function JiraController($scope, jiraService) {
                var _this = this;
                this.$scope = $scope;
                this.jiraService = jiraService;
                this.authorized = true;
                this.loading = false;
                this.setServer = function (server) {
                    _this.jiraServer = server || 'https://projects.dynamicplatform.com';
                };
                this.setJql = function (jql) {
                    _this.jql = jql || 'assignee=currentUser()';
                };
                this.setMaxResults = function (maxResults) {
                    maxResults = maxResults || 5;
                    if (maxResults < 1 || maxResults > 500)
                        maxResults = 5;
                    _this.maxResults = maxResults;
                };
                this.setRequestFields = function (requestFields) {
                    requestFields = requestFields || 'key';
                    if (typeof requestFields == 'string')
                        requestFields = requestFields.split(',');
                    _this.requestFields = requestFields;
                };
                this.setDisplayFields = function (displayFields) {
                    displayFields = displayFields || 'key';
                    if (typeof displayFields == 'string')
                        displayFields = displayFields.split(',');
                    _this.displayFields = displayFields;
                };
                this.reload = function () {
                    _this.set('loading', true);
                    _this.jiraService.jqlQuery(_this.jiraServer, _this.jql, _this.requestFields, _this.maxResults)
                        .then(function (results) {
                        _this.apply('model', results);
                    }).catch(function (error) {
                        _this.apply('authorized', false);
                        console.log("JiraService: jql query error", error);
                    }).finally(function () {
                        _this.apply('loading', false);
                    });
                };
                this.apply = function (name, value) {
                    _this.$scope.$evalAsync(function () {
                        _this[name] = value;
                        _this.$scope[name] = value;
                    });
                };
                this.set = function (name, value) {
                    _this[name] = value;
                };
                this.authorize = function () {
                    _this.set('loading', true);
                    _this.jiraService.authorize(_this.jiraServer, _this.username, _this.password)
                        .then(function (results) {
                        _this.apply('authorized', true);
                        _this.apply('password', '');
                        _this.reload();
                    }).catch(function (error) {
                        _this.apply('authorized', false);
                        console.log("JiraService: authorize error", error);
                    }).finally(function () {
                        _this.apply('loading', false);
                    });
                };
            }
            JiraController.prototype.getField = function (row, field) {
                if (row.hasOwnProperty(field))
                    return row[field];
                if (row.hasOwnProperty('fields') && row.fields.hasOwnProperty(field))
                    return row.fields[field];
                console.log("Row does not contain a value for field named '" + field + "'. Values are:", row);
                return '';
            };
            JiraController.prototype.getTitle = function (str) {
                function capitalize(str) {
                    var remainingChars = str.slice(1).toLowerCase();
                    return str.charAt(0).toUpperCase() + remainingChars;
                }
                ;
                return capitalize(str);
            };
            JiraController.$inject = ['$scope', 'app.jira.JiraService'];
            return JiraController;
        }());
        var JiraDirective = (function () {
            function JiraDirective($templateRequest, $compile, $timeout) {
                var _this = this;
                this.$templateRequest = $templateRequest;
                this.$compile = $compile;
                this.$timeout = $timeout;
                this.controllerAs = 'vm';
                this.controller = 'app.jira.JiraController';
                this.templateUrl = '/public/app/jira/jira.html';
                this.restrict = 'E';
                this.scope = {
                    jiraServer: '@server',
                    jql: '@jql',
                    maxResults: '@maxResults',
                    requestFields: '@requestFields',
                    displayFields: '@displayFields',
                    model: '=model'
                };
                this.link = function ($scope, element, attrs) {
                    var timeout = _this.$timeout($scope.vm.reload, 800);
                    attrs.$observe('server', function () {
                        _this.$timeout.cancel(timeout);
                        $scope.vm.setServer(attrs.server);
                        timeout = _this.$timeout($scope.vm.reload, 500);
                    });
                    attrs.$observe('jql', function () {
                        _this.$timeout.cancel(timeout);
                        $scope.vm.setJql(attrs.jql);
                        timeout = _this.$timeout($scope.vm.reload, 500);
                    });
                    attrs.$observe('maxResults', function () {
                        _this.$timeout.cancel(timeout);
                        $scope.vm.setMaxResults(attrs.maxResults);
                        timeout = _this.$timeout($scope.vm.reload, 500);
                    });
                    attrs.$observe('requestFields', function () {
                        _this.$timeout.cancel(timeout);
                        $scope.vm.setRequestFields(attrs.requestFields);
                        timeout = _this.$timeout($scope.vm.reload, 500);
                    });
                    attrs.$observe('displayFields', function () {
                        _this.$timeout.cancel(timeout);
                        $scope.vm.setDisplayFields(attrs.displayFields);
                        timeout = _this.$timeout($scope.vm.reload, 500);
                    });
                };
            }
            JiraDirective.$inject = ['$templateRequest', '$compile', '$timeout'];
            return JiraDirective;
        }());
        var slice = [].slice;
        function buildDirectiveFactory(ctor) {
            var factory;
            factory = function () {
                var args;
                args = 1 <= arguments.length ? slice.call(arguments, 0) : [];
                return (function (func, args, ctor) {
                    ctor.prototype = func.prototype;
                    var child = new ctor, result = func.apply(child, args);
                    return Object(result) === result ? result : child;
                })(ctor, args, function () { });
            };
            factory.$inject = ctor.$inject;
            return factory;
        }
        ;
        angular
            .module('app.jira')
            .directive('dpJira', buildDirectiveFactory(JiraDirective))
            .controller('app.jira.JiraController', JiraController)
            .service('app.jira.JiraService', ['$q', '$http', function ($q, $http) { return new JiraService($q, $http); }]);
        ;
    })(jira = app.jira || (app.jira = {}));
})(app || (app = {}));
