var app;
(function (app) {
    var giteditor;
    (function (giteditor) {
        var NewDialogController = (function () {
            function NewDialogController($http, $scope, $state, $mdDialog, $mdToast, gitDirectoryService, gitEditorService, serverRoutes) {
                var _this = this;
                this.$http = $http;
                this.$scope = $scope;
                this.$state = $state;
                this.$mdDialog = $mdDialog;
                this.$mdToast = $mdToast;
                this.gitDirectoryService = gitDirectoryService;
                this.gitEditorService = gitEditorService;
                this.serverRoutes = serverRoutes;
                this.working = false;
                this.selectedIndex = 0;
                this.basepath = '';
                this.name = '';
                this.canCreate = false;
                this.newFiles = [];
                this.commitMessage = '';
                this.createUploadMessage = 'Create';
                this.badCharsMessage = '';
                this.tabChange = function (newTab) {
                    if (newTab === 2) {
                        _this.createUploadMessage = 'Upload';
                    }
                    else {
                        _this.createUploadMessage = 'Create';
                    }
                    _this.buildPath();
                };
                this.buildPath = function () {
                    if (_this.selectedIndex < 2) {
                        _this.path = _this.basepath + _this.name;
                    }
                    else {
                        _this.path = _this.basepath;
                    }
                    _this.validate();
                };
                this.validate = function () {
                    if (_this.selectedIndex === 2) {
                        _this.canCreate = _this.newFiles.length > 0 && _this.commitMessage;
                        return _this.canCreate;
                    }
                    if (_this.name == undefined)
                        _this.name = '';
                    _this.badCharsMessage = '';
                    _this.canCreate = true;
                    if (!_this.name) {
                        _this.canCreate = false;
                        return _this.canCreate;
                    }
                    if (_this.name.match('[\\/:*?<>|]')) {
                        _this.canCreate = false;
                        _this.badCharsMessage = 'Name cannot include any of following characters: "\\/:*?<>|"';
                    }
                    return _this.canCreate;
                };
                this.hide = function () {
                    _this.$mdDialog.hide();
                };
                this.cancel = function () {
                    _this.$mdDialog.hide();
                };
                this.fileTypes = function () {
                    return _this.gitEditorService.editableExtensions();
                };
                this.create = function () {
                    if (!_this.validate())
                        return;
                    if (_this.selectedIndex === 0) {
                        _this.createFile();
                    }
                    else if (_this.selectedIndex === 1) {
                        _this.createFolder();
                    }
                    else {
                        _this.uploadFiles();
                    }
                };
                this.createFile = function () {
                    _this.working = true;
                    var lastDotIndex = _this.name.lastIndexOf(".");
                    var ext = '';
                    if (lastDotIndex !== -1)
                        ext = _this.name.substr(lastDotIndex + 1, _this.name.length - lastDotIndex + 1);
                    var file = {
                        fullname: _this.path.split('/').map(encodeURIComponent).join('/'),
                        name: _this.name,
                        extension: ext,
                        type: 'File',
                        exists: false,
                        parent: _this.gitDirectoryService.currentDirectory
                    };
                    console.log(file);
                    _this.gitDirectoryService.invalidateTree(_this.basepath);
                    _this.hide();
                    location.href = ('/edit/' + _this.path).replace(/[/]+/ig, '/');
                    return;
                };
                this.createFolder = function () {
                    _this.working = true;
                    _this.buildPath();
                    var uri = _this.serverRoutes.git + "newDirectory" + _this.path.split('/').map(encodeURIComponent).join('/');
                    debugger;
                    _this.$http.post(uri, {}).then(function () {
                        _this.gitDirectoryService.invalidateTree(_this.basepath.split('/').map(encodeURIComponent).join('/'));
                        _this.gitDirectoryService.loadTree(_this.basepath.split('/').map(encodeURIComponent).join('/')).then(function (directory) {
                            _this.gitDirectoryService.goDirectory(directory);
                            _this.hide();
                        });
                    }).catch(function (error) {
                        console.log("ERROR creating new directory", error);
                        _this.$mdToast.showSimple("New Directory failed");
                    });
                };
                this.uploadFiles = function () {
                    _this.working = true;
                    _this.gitDirectoryService.saveFile(_this.commitMessage, _this.newFiles).then(function () {
                        var dir = _this.gitDirectoryService.currentDirectory;
                        _this.gitDirectoryService.invalidateTree(_this.basepath);
                        _this.gitDirectoryService.loadDirectory(dir).then(function () {
                            _this.hide();
                            console.log("Closing dialog");
                        });
                    });
                };
                var cdir = gitDirectoryService.currentDirectory;
                ;
                if (cdir) {
                    this.basepath = cdir.fullname;
                }
                if (!this.basepath.endsWith('/')) {
                    this.basepath += '/';
                }
                $scope.$watch(angular.bind(this, function () {
                    return this.name;
                }), this.buildPath);
                $scope.$watch(angular.bind(this, function () {
                    return this.selectedIndex;
                }), this.tabChange);
                $scope.$watch(angular.bind(this, function () {
                    return this.commitMessage;
                }), this.validate);
                $scope.$watch(angular.bind(this, function () {
                    return this.newFiles;
                }), this.validate);
                this.buildPath();
            }
            NewDialogController.$inject = ['$http', '$scope', '$state', '$mdDialog', '$mdToast', 'app.giteditor.IGitDirectoryService', 'app.giteditor.IGitEditorService', 'IServerRoutes'];
            return NewDialogController;
        }());
        giteditor.NewDialogController = NewDialogController;
        angular.module('app.giteditor')
            .controller('app.giteditor.NewDialogController', NewDialogController);
    })(giteditor = app.giteditor || (app.giteditor = {}));
})(app || (app = {}));
