var app;
(function (app) {
    var giteditor;
    (function (giteditor) {
        var IFrameRenderer = (function () {
            function IFrameRenderer($q) {
                this.$q = $q;
                this.extensions = ['.pdf', '.xml'];
                this.requiresContent = false;
            }
            IFrameRenderer.prototype.render = function (content) { throw new Error("Not implemented"); };
            IFrameRenderer.prototype.renderFile = function (file) {
                var query = '';
                if (file.extension == '.pdf') {
                    query = '?view=Fit';
                }
                return {
                    isAsync: false,
                    immidate: '<iframe seamless style="min-width:100%; min-height: calc(100vh - 175px)" src="' + file.fullname + query + '"></iframe>',
                    eventual: this.$q.reject(false)
                };
            };
            return IFrameRenderer;
        }());
        giteditor.IFrameRenderer = IFrameRenderer;
        factory.$inject = [];
        function factory($q) {
            return new IFrameRenderer($q);
        }
        angular
            .module('app.giteditor')
            .service('app.giteditor.renderersIFrameRenderer', ['$q', factory]);
    })(giteditor = app.giteditor || (app.giteditor = {}));
})(app || (app = {}));
