var app;
(function (app) {
    var users;
    (function (users) {
        'use strict';
        var LoginController = (function () {
            function LoginController($timeout, $scope, $http, authService, settingsService, directoryService) {
                var _this = this;
                this.$timeout = $timeout;
                this.$scope = $scope;
                this.$http = $http;
                this.authService = authService;
                this.settingsService = settingsService;
                this.directoryService = directoryService;
                this.working = false;
                this.redirecting = false;
                this.persist = false;
                this.title = window.location.hostname;
                this.checkAuth = function () {
                    var success = function (isLoggedIn) {
                        if (isLoggedIn) {
                            _this.working = false;
                            _this.redirecting = true;
                            window.location.hash = '';
                            _this.settingsService.setUsername(_this.username);
                        }
                        else {
                            _this.working = false;
                            _this.redirecting = false;
                        }
                    };
                    _this.authService.isLoggedIn().then(success);
                };
                this.login = function () {
                    _this.working = true;
                    _this.authService.login(_this.username, _this.password, _this.persist)
                        .then(function (worked) {
                        if (worked) {
                            _this.working = false;
                            _this.redirecting = true;
                            localStorage.setItem('wopr-username', _this.username);
                            _this.settingsService.setUsername(_this.username);
                            debugger;
                            var uri = localStorage.getItem('unauth-nexthop') ? localStorage.getItem('unauth-nexthop') : location.hash && location.hash.length > 1 ? decodeURI(decodeURI(location.hash).substr(1).replace(/%2F/g, '/')) : '';
                            if (uri.toLocaleLowerCase().indexOf('/browse') != 0)
                                uri = '/browse' + uri;
                            if (uri === '/browse/login')
                                uri = '/browse';
                            if (uri) {
                                location.hash = '';
                                localStorage.setItem('unauth-nexthop', null);
                                location.pathname = uri;
                            }
                            else
                                location.pathname = '/browse';
                        }
                        else {
                            _this.message = 'Username or Password not recognized';
                            _this.working = false;
                        }
                    }).catch(function (error) { console.log(error); });
                };
            }
            LoginController.prototype.keydown = function ($event) {
                if ($event.originalEvent.keyCode == 13) {
                    this.login();
                }
            };
            LoginController.$inject = ['$timeout', '$scope', '$http', 'app.services.IAuthService', 'app.settings.ISettingsService', 'app.giteditor.IGitDirectoryService'];
            return LoginController;
        }());
        angular
            .module('app.login')
            .controller('app.login.LoginController', LoginController);
    })(users = app.users || (app.users = {}));
})(app || (app = {}));
