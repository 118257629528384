var app;
(function (app) {
    var services;
    (function (services) {
        'use strict';
        var AuthService = (function () {
            function AuthService($http, $timeout, $q, $location, $state) {
                var _this = this;
                this.$http = $http;
                this.$timeout = $timeout;
                this.$q = $q;
                this.$location = $location;
                this.$state = $state;
                this.storageKey = 'app.services.IAuthService.token';
                this.persist = !sessionStorage.getItem('app.services.IAuthService.token');
                this.logonChanged = new LiteEvent();
                this.isLoggedIn = function (cleanUp) {
                    if (cleanUp === void 0) { cleanUp = false; }
                    var qd = _this.$q.defer();
                    _this.$http.post('/api/auth', {}).then(function (response) {
                        if (cleanUp && response.data) {
                            if (!_this.getToken() || _this.getToken() === 'null') {
                                _this.logout();
                                return _this.$q.when(false);
                            }
                        }
                        if (!response.data && !localStorage.getItem('unauth-nexthop'))
                            localStorage.setItem('unauth-nexthop', location.pathname);
                        _this.delayRefresh();
                        qd.resolve(response.data);
                    }).catch(function (error) {
                        console.log("DEBUG: ", error);
                        qd.resolve(false);
                    });
                    return qd.promise;
                };
                this.login = function (username, password, persist) {
                    _this.persist = persist;
                    var qd = _this.$q.defer();
                    _this.$http({
                        method: 'POST',
                        url: 'token',
                        data: $.param({ grant_type: 'password', username: username, password: password, remember: persist }),
                        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
                    }).then(function (response) {
                        var data = response.data;
                        if (data.error === 'invalid_grant') {
                            _this.logonChanged.trigger(false);
                            qd.resolve(false);
                            return;
                        }
                        else if (data.access_token) {
                            _this.setToken(data.access_token);
                            _this.logonChanged.trigger(true);
                            _this.delayRefresh();
                            qd.resolve(response.data);
                            return;
                        }
                        console.log('WARN: auth service fall though, bad response?');
                        _this.logonChanged.trigger(false);
                        qd.resolve(false);
                    });
                    return qd.promise;
                };
                this.logout = function () {
                    sessionStorage.clear();
                    localStorage.clear();
                    indexedDB.deleteDatabase('dpData');
                    _this.$http.post('/api/auth/signout', {}).then(function () {
                        _this.logonChanged.trigger(false);
                        _this.delayRefresh();
                        if (_this.refreshCancellation)
                            _this.$timeout.cancel(_this.refreshCancellation);
                        console.log("nav to login...");
                        _this.$location.hash(location.pathname);
                        _this.$state.go("login");
                    });
                };
                this.refreshCancellation = null;
                this.lastRefreshedToken = null;
                this.lastRefreshedCookie = null;
                this.refreshAuth = function () {
                    if (moment().diff(moment(_this.lastRefreshedToken), 'hours') > 5) {
                        console.log('refresh auth token...');
                        _this.$http.post('/api/auth/refreshToken', {}).then(function (transport) {
                            _this.setToken(transport.data);
                        }).catch(function (error) {
                            console.log('Failed to refresh Auth:', error);
                        });
                    }
                    if (moment().diff(moment(_this.lastRefreshedCookie), 'minutes') > 5) {
                        console.log('refresh auth cookie...');
                        _this.$http.post('/api/auth/refreshCookie', {}).then(function () {
                        }).catch(function (error) {
                            console.log('Failed to refresh Auth:', error);
                        });
                    }
                    _this.delayRefresh();
                };
                this.delayRefresh = function () {
                    if (_this.refreshCancellation)
                        _this.$timeout.cancel(_this.refreshCancellation);
                    console.log('delayRefresh (1 min)');
                    _this.refreshCancellation = _this.$timeout(_this.refreshAuth, 60000);
                };
                this.getStorage = function () {
                    if (_this.persist)
                        return localStorage;
                    return sessionStorage;
                };
                this.getToken = function () {
                    return _this.getStorage().getItem(_this.storageKey);
                };
                this.setToken = function (token) {
                    _this.$http.defaults.headers.common.Authorization = 'Bearer ' + token;
                    _this.token = token;
                    _this.lastRefreshedToken = new Date();
                    _this.lastRefreshedCookie = new Date();
                    _this.getStorage().setItem(_this.storageKey, token);
                };
                if (localStorage)
                    this.setToken(this.getToken());
            }
            return AuthService;
        }());
        factory.$inject = ['$http', '$timeout', '$q', '$location', '$state'];
        function factory($http, $timeout, $q, $location, $state) {
            return new AuthService($http, $timeout, $q, $location, $state);
        }
        angular
            .module('app.services')
            .factory('app.services.IAuthService', factory);
    })(services = app.services || (app.services = {}));
})(app || (app = {}));
