(function () {
    'use strict';
    angular
        .module('app.giteditor')
        .config(['$stateProvider', '$urlRouterProvider', '$urlMatcherFactoryProvider', config]);
    function config($stateProvider, $urlRouterProvider, $urlMatcherFactory) {
        function valToString(val) { return val != null ? val.toString() : val; }
        function valFromString(val) { return val != null ? val.toString() : val; }
        function regexpMatches(val) { return this.pattern.test(val); }
        $urlMatcherFactory.type("path", {
            encode: valToString,
            decode: valFromString,
            is: regexpMatches,
            pattern: /.*/
        });
        $urlRouterProvider.otherwise('/browse');
        $stateProvider
            .state('browse', {
            url: '/',
            templateUrl: '/public/app/giteditor/giteditor.html',
            controller: 'app.giteditor.GiteditorController',
            controllerAs: 'vm'
        })
            .state('browse-directory', {
            parent: 'browse',
            url: 'browse{path:path}',
            data: {
                currentDirectory: null
            },
            views: {
                'nav': {
                    templateUrl: '/public/app/giteditor/giteditor.directorynav.html',
                    controller: 'app.giteditor.GiteditorDirectoryNavController',
                    controllerAs: 'vm'
                },
                'preview': {
                    templateUrl: '/public/app/giteditor/giteditor.renderer.html',
                    controller: 'app.giteditor.RendererController',
                    controllerAs: 'vm',
                }
            }
        })
            .state('edit-file', {
            url: '/edit{path:path}',
            templateUrl: '/public/app/giteditor/giteditor.editor.html',
            controller: 'app.giteditor.EditorController',
            controllerAs: 'vm',
            data: {
                currentFile: null
            }
        })
            .state('browse-file', {
            url: '/view{path:path}',
            templateUrl: '/public/app/giteditor/giteditor.renderer.html',
            controller: 'app.giteditor.RendererController',
            controllerAs: 'vm',
            data: {
                currentFile: null
            }
        });
    }
})();
