var app;
(function (app) {
    var giteditor;
    (function (giteditor) {
        'use strict';
        var ViewerJsRenderer = (function () {
            function ViewerJsRenderer() {
                this.extensions = ['.docx', '.odt', '.xlsx', '.odp', '.ods'];
                this.requiresContent = false;
            }
            ViewerJsRenderer.prototype.render = function (content) {
                throw new Error("Not implemented");
            };
            ViewerJsRenderer.prototype.renderFile = function (file) {
                return {
                    isAsync: false,
                    eventual: null,
                    immidate: "<iframe seamless style=\"min-width:100%; min-height: calc(100vh - 175px)\" src=\"/public/ViewerJS.html#" + file.fullname + "\" allowfullscreen webkitallowfullscreen></iframe>"
                };
            };
            return ViewerJsRenderer;
        }());
        giteditor.ViewerJsRenderer = ViewerJsRenderer;
        function factory() {
            return new ViewerJsRenderer();
        }
        angular
            .module('app.giteditor')
            .service('app.giteditor.renderersViewerJsRenderer', ['$q', '$sce', 'app.giteditor.IHighlighterService', factory]);
    })(giteditor = app.giteditor || (app.giteditor = {}));
})(app || (app = {}));
