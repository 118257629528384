'use strict';
var app;
(function (app) {
    angular
        .module('app.core', [
        'ui.router',
        'ngSanitize',
        'ngCookies',
        'ngMaterial',
        'SvgPanZoom',
        'ngTable'
    ]);
})(app || (app = {}));
