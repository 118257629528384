var app;
(function (app) {
    var giteditor;
    (function (giteditor) {
        var BreadcrumbController = (function () {
            function BreadcrumbController($scope, directoryService, contextService, observeOnScope) {
                var _this = this;
                this.directoryService = directoryService;
                this.breadcrumbs = [];
                this.go = function (fso) {
                    if (fso.type == 'File') {
                        _this.directoryService.goFile(fso);
                    }
                    else {
                        _this.directoryService.goDirectory(fso);
                    }
                };
                this.goHome = function () {
                    return _this.directoryService.goPath('/');
                };
                var subscriber = contextService
                    .getContext("selectedFilesystemObject")
                    .distinctUntilChanged()
                    .safeApply($scope, function (fso) {
                    _this.selectedFileSystemObject = fso;
                })
                    .subscribe();
                $scope.$on("$destroy", function () {
                    contextService.dispose("selectedFilesystemObject", subscriber);
                });
                observeOnScope($scope, function () { return _this.selectedFileSystemObject; })
                    .distinctUntilChanged()
                    .map(function (newAndOldValues) {
                    return newAndOldValues.newValue;
                })
                    .safeApply($scope, function (fso) {
                    if (!fso) {
                        _this.breadcrumbs = [];
                        return;
                    }
                    var crumbs = [];
                    var d = fso;
                    while (d != null && d.parent != null) {
                        crumbs.unshift(d);
                        d = d.parent;
                    }
                    _this.breadcrumbs = crumbs;
                }).subscribe();
            }
            BreadcrumbController.$inject = ['$scope', 'app.giteditor.IGitDirectoryService', 'app.context.IContextService', 'observeOnScope'];
            return BreadcrumbController;
        }());
        var BreadcrumbDirective = (function () {
            function BreadcrumbDirective() {
                this.template = '<md-toolbar class="breadcrumbs md-hue-1"><a class="breadcrumb" md-ink-ripple ng-click="vm.goHome()">home</a><a ng-repeat="directory in vm.breadcrumbs" class="breadcrumb" md-ink-ripple ng-click="vm.go(directory)">{{directory.name}}</a></md-toolbar>';
                this.controllerAs = 'vm';
                this.controller = 'app.giteditor.BreadcrumbController';
                this.restrict = 'E';
                this.scope = {};
            }
            return BreadcrumbDirective;
        }());
        var slice = [].slice;
        function buildDirectiveFactory(ctor) {
            var factory = function () {
                var args = 1 <= arguments.length ? slice.call(arguments, 0) : [];
                return (function (func, args, ctor) {
                    ctor.prototype = func.prototype;
                    var child = new ctor, result = func.apply(child, args);
                    return Object(result) === result ? result : child;
                })(ctor, args, function () { });
            };
            factory.$inject = ctor.$inject;
            return factory;
        }
        ;
        angular
            .module('app.giteditor')
            .directive('dpBreadcrumbs', buildDirectiveFactory(BreadcrumbDirective))
            .controller('app.giteditor.BreadcrumbController', BreadcrumbController);
    })(giteditor = app.giteditor || (app.giteditor = {}));
})(app || (app = {}));
