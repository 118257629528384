var app;
(function (app) {
    var giteditor;
    (function (giteditor) {
        var NullRenderer = (function () {
            function NullRenderer($q) {
                this.$q = $q;
                this.extensions = [];
                this.requiresContent = false;
            }
            NullRenderer.prototype.render = function (content) { throw new Error("Not implemented"); };
            NullRenderer.prototype.renderFile = function (file) {
                return {
                    isAsync: false,
                    immidate: 'This file cannot currently be rendered online. Please download and edit offline',
                    eventual: this.$q.reject(false)
                };
            };
            return NullRenderer;
        }());
        giteditor.NullRenderer = NullRenderer;
        factory.$inject = [];
        function factory($q) {
            return new giteditor.IFrameRenderer($q);
        }
        angular
            .module('app.giteditor')
            .service('app.giteditor.renderersNullRenderer', ['$q', factory]);
    })(giteditor = app.giteditor || (app.giteditor = {}));
})(app || (app = {}));
