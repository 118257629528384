var app;
(function (app) {
    var fileupload;
    (function (fileupload) {
        'use strict';
        var FileUploadController = (function () {
            function FileUploadController($scope, $http) {
                var _this = this;
                this.$scope = $scope;
                this.$http = $http;
                this.files = [];
                this.rootPath = '';
                this.message = 'Drag & drop files onto window to upload';
                this.removeUpload = function (file) {
                    _this.files.splice(_this.files.indexOf(file), 1);
                };
                this.files = ($scope.files) ? $scope.files : [];
                this.message = $scope.message !== typeof (undefined) ? $scope.message : this.message;
                this.rootPath = $scope.rootPath ? $scope.rootPath : this.rootPath;
            }
            FileUploadController.$inject = ['$scope', '$http'];
            return FileUploadController;
        }());
        var FileUploadDirective = (function () {
            function FileUploadDirective() {
                this.controller = 'app.fileupload.FileUploadController';
                this.controllerAs = 'vm';
                this.restrict = 'A';
                this.templateUrl = '/public/app/fileupload/fileupload.html';
                this.scope = {
                    files: '=files',
                    message: '=message',
                    rootPath: '=rootPath'
                };
                this.link = function (scope, element, attrs, controller) {
                    element[0].style.backgroundColor = 'red';
                    var processLeave = function (event) {
                        if (event != null) {
                            event.preventDefault();
                        }
                        element[0].style.backgroundColor = 'red';
                    };
                    var processEnter = function (event) {
                        if (event != null) {
                            event.preventDefault();
                        }
                        element[0].style.backgroundColor = 'green';
                        return false;
                    };
                    var win = angular.element(document.querySelector("html"));
                    win.bind('dragleave', processLeave);
                    win.bind('dragenter', processEnter);
                    win.bind('dragover', processEnter);
                    win.bind('drop', function (event) {
                        if (event != null) {
                            event.preventDefault();
                        }
                        function getFile(file) {
                            var reader = new FileReader();
                            reader.onload = function (evt) {
                                console.log(evt.target);
                                return scope.$apply(function () {
                                    controller.files.push({
                                        file: evt.target.result,
                                        metadata: {
                                            fullname: controller.rootPath + file.name,
                                            name: file.name,
                                            extension: file.name.substr(file.name.lastIndexOf('.')),
                                            exists: false,
                                            type: 'File'
                                        }
                                    });
                                });
                            };
                            reader.readAsArrayBuffer(file);
                        }
                        for (var _i = 0, _a = event.originalEvent.dataTransfer.files; _i < _a.length; _i++) {
                            var file = _a[_i];
                            getFile(file);
                        }
                        return false;
                    });
                    return element;
                };
            }
            return FileUploadDirective;
        }());
        var slice = [].slice;
        function buildDirectiveFactory(ctor) {
            var factory;
            factory = function () {
                var args;
                args = 1 <= arguments.length ? slice.call(arguments, 0) : [];
                return (function (func, args, ctor) {
                    ctor.prototype = func.prototype;
                    var child = new ctor, result = func.apply(child, args);
                    return Object(result) === result ? result : child;
                })(ctor, args, function () { });
            };
            factory.$inject = ctor.$inject;
            return factory;
        }
        ;
        angular
            .module('app.fileupload')
            .controller('app.fileupload.FileUploadController', FileUploadController)
            .directive('fileupload', buildDirectiveFactory(FileUploadDirective));
    })(fileupload = app.fileupload || (app.fileupload = {}));
})(app || (app = {}));
