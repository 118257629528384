var app;
(function (app) {
    var giteditor;
    (function (giteditor) {
        'use strict';
        var NullEditorController = (function () {
            function NullEditorController() {
                this.setContent = function (content) { };
                this.view = "browse/app/giteditor/editors/nullEditor.html";
                this.extensions = [];
            }
            NullEditorController.$inject = [];
            return NullEditorController;
        }());
        giteditor.NullEditorController = NullEditorController;
        angular
            .module('app.giteditor')
            .controller('app.giteditor.editorsNullEditorController', NullEditorController);
    })(giteditor = app.giteditor || (app.giteditor = {}));
})(app || (app = {}));
