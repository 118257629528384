var app;
(function (app) {
    var giteditor;
    (function (giteditor) {
        'use strict';
        var HighlighterService = (function () {
            function HighlighterService($q) {
                var _this = this;
                this.$q = $q;
                this.callbacks = {};
                this.highlight = function (code) {
                    function guid() {
                        function s4() {
                            return Math.floor((1 + Math.random()) * 0x10000)
                                .toString(16)
                                .substring(1);
                        }
                        return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
                            s4() + '-' + s4() + s4() + s4();
                    }
                    var uuid = guid();
                    var qd = _this.$q.defer();
                    _this.callbacks[uuid] = qd;
                    HighlighterService.worker.postMessage({ code: code, uuid: uuid });
                    return qd.promise;
                };
                this.callback = function (data) {
                    if (!_this.callbacks.hasOwnProperty(data.uuid)) {
                        console.error("Un-registered highlight response");
                        return;
                    }
                    _this.callbacks[data.uuid].resolve('<pre><code class="json">' + data.html + '</code></pre>');
                    delete _this.callbacks[data.uuid];
                };
                HighlighterService.worker.onmessage = function (messageEvent) {
                    _this.callback(messageEvent.data);
                };
            }
            HighlighterService.worker = new Worker('/public/lib/highlighterServiceWorker.js');
            HighlighterService.$inject = ['$q'];
            return HighlighterService;
        }());
        giteditor.HighlighterService = HighlighterService;
        angular
            .module('app.giteditor')
            .service('app.giteditor.IHighlighterService', ['$q', HighlighterService]);
    })(giteditor = app.giteditor || (app.giteditor = {}));
})(app || (app = {}));
