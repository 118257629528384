var app;
(function (app) {
    var dp;
    (function (dp) {
        var CSV = (function () {
            function CSV() {
            }
            CSV.parse = function (csvStr) {
                var promise = new Promise(function (resolve, reject) {
                    var complete = function (results) {
                        if (results && results.errors && results.errors.length > 0) {
                            console.log("CSV parse errors:", results.errors);
                        }
                        resolve({ data: results.data.data, headers: results.data.meta.fields });
                    };
                    var worker = new Worker('/public/lib/papaparse.min.js');
                    worker.onmessage = complete;
                    worker.postMessage(csvStr);
                });
                return promise;
            };
            return CSV;
        }());
        var DpJsonLegacy = (function () {
            function DpJsonLegacy() {
                var _this = this;
                this.lastUrl = 'not-a-url';
                this.restrict = 'A';
                this.link = function (scope, element, attributes) {
                    debugger;
                    _this.lastUrl = attributes['dpJson'];
                    element.html('<span><i class="fa fa-spin fa-spinner"></i> Loading...</span>');
                    repo.get(attributes['dpJson']).then(function (data) {
                        var html = '<span><i class="fa fa-exclamation-triangle"></i> Parse Error 1</span>';
                        try {
                            if (attributes.hasOwnProperty('dpJsonProperty')) {
                                if (typeof (data) === 'string') {
                                    data = JSON.parse(data);
                                }
                                for (var _i = 0, _a = attributes['dpJsonProperty'].split('.'); _i < _a.length; _i++) {
                                    var n = _a[_i];
                                    data = data[n];
                                }
                            }
                            if (typeof data === 'string') {
                                html = data;
                            }
                            else {
                                html = '<pre>' + JSON.stringify(data, null, 2) + '</pre>';
                            }
                        }
                        catch (exception) {
                            html = '<span><i class="fa fa-exclamation-triangle"></i> Parse Error</span>';
                        }
                        element.html(html);
                    }, function (error) {
                        element.html('<span><i class="fa fa-exclamation-triangle"></i> Transport Error</span>');
                        console.error(error);
                    });
                };
            }
            return DpJsonLegacy;
        }());
        var scopeNumber = 0;
        function addEditButton(element, path, gitService) {
            var view = $('<button class="control pull-right" style="border: none;color: gray;background: transparent;"><i class="fa fa-eye"></i></button>');
            var edit = $('<button class="control pull-right" style="margin-right:24px;border: none;color: gray;background: transparent;"><i class="fa fa-pencil"></i></button>');
            var container = $('<div style="width:100%"></div>');
            element.wrap(container);
            element.before(edit);
            element.before(view);
            view.click(function () {
                debugger;
                var lastDot = path.lastIndexOf('.');
                var lastSlash = path.lastIndexOf('/');
                gitService.goFile({
                    name: path.substr(lastSlash, path.length - lastSlash),
                    fullname: path,
                    exists: true,
                    extension: path.substr(lastDot, path.length - lastDot),
                    type: 'file',
                    parent: gitService.currentFile.parent
                });
            });
            edit.click(function () {
                debugger;
                var lastDot = path.lastIndexOf('.');
                var lastSlash = path.lastIndexOf('/');
                gitService.goEditFile({
                    name: path.substr(lastSlash, path.length - lastSlash),
                    fullname: path,
                    exists: true,
                    extension: path.substr(lastDot, path.length - lastDot),
                    type: 'file',
                    parent: gitService.currentFile.parent
                });
            });
        }
        function renderJsonPropery(element, data, propertyName) {
            element.parent().find('.control').css('top', element.offset().top + 62);
            var html = '';
            try {
                for (var _i = 0, _a = propertyName.split('.'); _i < _a.length; _i++) {
                    var n = _a[_i];
                    data = data[n];
                }
                if (typeof data === 'string') {
                    html = data;
                }
                else {
                    html = '<pre>' + JSON.stringify(data, null, 2) + '</pre>';
                }
            }
            catch (exception) {
                html = "<span><i class=\"fa fa-exclamation-triangle\"></i> Parse Error</span><br>" + data;
            }
            element.html(html);
        }
        function renderJson(element, data) {
            element.parent().find('.control').css('top', element.offset().top + 62);
            var html = '';
            try {
                if (typeof data === 'string') {
                    html = data;
                }
                else {
                    html = '<pre>' + JSON.stringify(data, null, 2) + '</pre>';
                }
            }
            catch (exception) {
                html = "<span><i class=\"fa fa-exclamation-triangle\"></i> Parse Error</span><br>" + data;
            }
            element.html(html);
        }
        function renderJsonSchema(element, data, schema, path, scope, $mdToast) {
            element.parent().find('.control').css('top', element.offset().top + 62);
            console.log(data);
            debugger;
            var options = {
                theme: 'html',
                startval: typeof (data) == 'string' ? JSON.parse(data) : data,
                disable_array_delete: true,
                disable_array_reorder: true,
                disable_collapse: true,
                no_additional_properties: false,
                schema: schema
            };
            element.find('span:first').hide();
            var editor = new JSONEditor(element[0], options);
            var $saveButton = $('<button>Save</button>');
            var $commitButton = $('<button>Commit</button>');
            var $input = $('<input type="text" placeholder="Commit Message Here...">');
            var $wrapper = $('<span style="display: inline-block; margin-left: 10px; font-size: 0.8em; vertical-align: middle;"></span>');
            $wrapper.append($saveButton);
            element.find('h3:first').append($wrapper);
            $saveButton.on('click', function () {
                var errors = editor.validate();
                if (errors.length > 0) {
                    var message = 'Fix isues first:';
                    for (var _i = 0, errors_1 = errors; _i < errors_1.length; _i++) {
                        var m = errors_1[_i];
                        console.log(m);
                        message += "\r\n" + m.message;
                    }
                    $mdToast.showSimple(message);
                    return;
                }
                $mdToast.showSimple('Saving...');
                repo.setRepoFile(path, editor.getValue())
                    .then(function () { return $mdToast.showSimple('Save Complete.'); })
                    .catch(function (error) {
                    console.error(error);
                    $mdToast.showSimple('Save Failed!');
                });
            });
            $commitButton.on('click', function () {
                console.log('commit attempt,... not done');
            });
            element.find('span:first').hide();
            scope.$on('$destroy', editor.destroy);
        }
        function renderAce(element, data, path, scope, $compile, $mdToast) {
            element.parent().find('.control').css('top', element.offset().top + 62);
            var theme = 'chrome';
            if (localStorage.getItem('theme')) {
                var localTheme = JSON.parse(localStorage.getItem('theme'));
                theme = localTheme.ace || theme;
            }
            var ext = path.substr(path.lastIndexOf('.')).toLowerCase();
            var mode = 'text';
            switch (ext) {
                case '.xml':
                case '.svg':
                    mode = 'xml';
                    break;
                case '.md':
                    mode = 'markdown';
                    break;
                case '.json':
                    mode = 'json';
                    break;
                case '.js':
                    mode = 'javascript';
                    break;
                case '.jsx':
                    mode = 'jsx';
                    break;
                case '.html':
                    mode = 'html';
                    break;
                case '.css':
                    mode = 'css';
                    break;
                case '.ps1':
                case '.psm1':
                case '.psd1':
                    mode = 'powershell';
                    break;
                default:
                    mode = 'text';
                    break;
            }
            ;
            scope.data = data;
            scope.onChange = function (data) {
                console.log(data);
            };
            var template = "<div>this is a part of the template.\n<div flex style=\"min-height: 500px; height: calc(100vh - 70px - 64px - 30px - 100px);\" ui-ace=\"{\n    require: ['ace/ext/language_tools', 'ace/ext/ext-elastic_tabstops_lite', 'ace/ext/ext-spellcheck', 'ace/ext/ext-searchbox','ace/ext/ext-beautify'],\n    useWrapMode : true,\n    showGutter: true,\n    showPrintMargin: false,\n}\"\nng-model=\"data\">{{data}}</div>\n<pre>\n     {{data}}\n</pre></div>\n";
            $compile(template)(scope);
            element.html(template);
            scope.$digest();
        }
        function renderTable(element, tableData, scope, NgTableParams, $compile, $timeout) {
            element.parent().find('.control').css('top', element.offset().top + 62);
            if (typeof (tableData.headers) === 'undefined'
                && typeof tableData[Symbol.iterator] !== 'function') {
                element.html("<span><i class=\"fa fa-exclamation-triangle\"></i> Data is not an array:  Error: Cannot format as a table if the data is not tabular</span>");
                return;
            }
            var headersArray = [];
            if (typeof (tableData.headers) === 'undefined') {
                var headers_1 = {};
                for (var _i = 0, tableData_1 = tableData; _i < tableData_1.length; _i++) {
                    var row = tableData_1[_i];
                    for (var _a = 0, _b = Object.keys(row); _a < _b.length; _a++) {
                        var key = _b[_a];
                        headers_1[key] = "<td data-title=\"'" + key + "'\" filter=\"{ '" + key + "': 'text'}\" sortable=\"'" + key + "'\">{{row['" + key + "']}}</td>";
                    }
                }
                headersArray = Object.keys(headers_1).map(function (h) { return headers_1[h]; });
            }
            else {
                headersArray = tableData.headers.map(function (key) { return ("<td data-title=\"'" + key + "'\" filter=\"{ '" + key + "': 'text'}\" sortable=\"'" + key + "'\">{{row['" + key + "']}}</td>"); });
                tableData = tableData.data;
            }
            console.log(tableData);
            scopeNumber++;
            debugger;
            var name = 'table' + scopeNumber;
            scope[name] = new NgTableParams({
                count: tableData.length
            }, {
                paginationMaxBlocks: 10,
                paginationMinBlocks: 2,
                dataset: tableData
            });
            var template = angular.element("\n<table ng-table=\"" + name + "\" class=\"table\" show-filter=\"true\">\n<tr ng-repeat=\"row in $data track by $index\">\n" + headersArray.join("\n") + "\n</tr>\n</table>");
            $compile(template)(scope);
            element.html(template);
            scope.$apply();
        }
        var DpInline = (function () {
            function DpInline($http, $compile, $mdToast, $timeout, NgTableParams, gitService) {
                var _this = this;
                this.$http = $http;
                this.$compile = $compile;
                this.$mdToast = $mdToast;
                this.$timeout = $timeout;
                this.NgTableParams = NgTableParams;
                this.gitService = gitService;
                this.url = 'not-a-url';
                this.restrict = 'A';
                this.scope = {};
                this.link = function (scope, element, attributes) {
                    console.log('dp-json - ' + attributes['dpInline']);
                    _this.url = attributes['dpInline'];
                    scope.path = _this.url;
                    element.html("<span><i class=\"fa fa-spin fa-circle-o-notch\"></i> Loading " + attributes['dpInline'] + "...</span>");
                    var ext = _this.url.substr(_this.url.lastIndexOf('.') + 1).toLowerCase();
                    var editor = attributes.hasOwnProperty('dpEditor') ? attributes['dpEditor'] : 'ace';
                    var format = attributes.hasOwnProperty('dpFormat') ? attributes['dpFormat'].toLowerCase() : ext;
                    addEditButton(element, _this.url, _this.gitService);
                    repo.get(attributes['dpInline']).then(function (data) {
                        if (attributes.hasOwnProperty('dpEscape')) {
                            switch (attributes['dpEscape']) {
                                case 'base64':
                                    data = btoa(data);
                                    break;
                                case 'url':
                                    data = decodeURI(data);
                                    break;
                                case 'html':
                                default:
                                    data = data.replace(/</g, '&lt;').replace(/>/g, '&gt;');
                            }
                        }
                        if (attributes.hasOwnProperty('dpPrepend')) {
                            data = attributes['dpPrepend'] + data;
                        }
                        if (attributes.hasOwnProperty('dpAppend')) {
                            data = data + attributes['dpAppend'];
                        }
                        switch (format) {
                            case 'json':
                                if (attributes.hasOwnProperty('dpJsonPath')) {
                                    return renderJsonPropery(element, data, attributes['dpJsonPath']);
                                }
                                if (attributes.hasOwnProperty('dpJsonSchema') || typeof (data['$schema']) !== 'undefined') {
                                    element.html('<span><i class="fa fa-spin fa-spinner"></i> Loading Schema...</span>');
                                    return _this.$http.get(attributes['dpJsonSchema'] || data['$schema']).then(function (schemaT) {
                                        if (editor === 'schema') {
                                            return renderJsonSchema(element, data, schemaT.data, attributes['dpInline'], scope, _this.$mdToast);
                                        }
                                        return renderJson(element, data);
                                    }).catch(function (error) {
                                        element.html('<span><i class="fa fa-exclamation-triangle"></i> Schema Transport Error</span>');
                                        console.error(error);
                                        return renderJson(element, data);
                                    });
                                }
                                break;
                            case 'table':
                                var tableData = void 0;
                                element.html("<span><i class=\"fa fa-spin fa-circle-o-notch\"></i> Parsing " + attributes['dpInline'] + "...</span>");
                                try {
                                    tableData = ext === 'json' ? Promise.resolve(JSON.parse(data)) : CSV.parse(data);
                                    tableData
                                        .then(function (data) { renderTable(element, data, scope, _this.NgTableParams, _this.$compile, _this.$timeout); })
                                        .catch(function (e) { throw new Error(e); });
                                }
                                catch (e) {
                                    element.html("<span><i class=\"fa fa-exclamation-triangle\"></i> Failed to parse data:  Error: " + e + "</span><br><hr><br><pre>" + data + "</pre>");
                                }
                                return;
                            default:
                                break;
                        }
                        return renderJson(element, data);
                    }, function (error) {
                        element.html('<span><i class="fa fa-exclamation-triangle"></i> Transport Error</span>');
                        console.error(error);
                    });
                };
            }
            DpInline.$inject = ['$http', '$compile', '$mdToast', '$timeout', 'NgTableParams', 'app.giteditor.IGitDirectoryService'];
            return DpInline;
        }());
        var slice = [].slice;
        function buildDirectiveFactory(ctor) {
            var factory;
            factory = function () {
                var args;
                args = 1 <= arguments.length ? slice.call(arguments, 0) : [];
                return (function (func, args, ctor) {
                    ctor.prototype = func.prototype;
                    var child = new ctor, result = func.apply(child, args);
                    return Object(result) === result ? result : child;
                })(ctor, args, function () { });
            };
            factory.$inject = ctor.$inject;
            return factory;
        }
        ;
        angular
            .module('app.dp')
            .directive('dpJson', buildDirectiveFactory(DpJsonLegacy))
            .directive('dpInline', buildDirectiveFactory(DpInline));
    })(dp = app.dp || (app.dp = {}));
})(app || (app = {}));
