(function () {
    'use strict';
    run.$inject = [
        '$rootScope',
        '$cookies',
        'currentUser',
        'app.services.IAuthService',
        '$location',
        '$state'
    ];
    function run($rootScope, $cookies, currentUser, authService, $location, $state) {
        $rootScope.$on('$stateChangeError', function (event, toState, toParams, fromState, fromParams, error) {
            console.log('stateChangeError');
            console.log(toState, toParams, fromState, fromParams, error);
            if (error.status == 401) {
                console.log("401 detected. Redirecting...");
            }
        });
        authService.isLoggedIn(true).then(function (loggedin) {
            console.log("authService: loggedin: " + loggedin);
            if (!loggedin && $state.current.name !== "login") {
                $state.go("login");
            }
        }).catch(function (error) {
            console.log("AuthService Failed: ", error);
            $state.go("login");
        });
        $rootScope.$on('$routeChangeError', function () {
            console.log("WARN: route change error");
        });
        currentUser.userId = $cookies.userId;
    }
    angular
        .module('app')
        .run(run);
})();
