var app;
(function (app) {
    var dp;
    (function (dp) {
        'use strict';
        var SiteController = (function () {
            function SiteController(directoryService) {
                var _this = this;
                this.directoryService = directoryService;
                this.noItemsMessage = 'Start typing the name of a user or computer to search site data...';
                this.isSite = false;
                this.selectedItemChanged = function (searchable) {
                    _this.selectedUser = null;
                    _this.selectedComputer = null;
                    _this.selectedDiskReport = null;
                    _this.selectedGoogleUser = null;
                    _this.selectedO365User = null;
                    if (!searchable)
                        return;
                    if (searchable.type == 'User') {
                        _this.selectedUser = searchable.data;
                    }
                    if (searchable.type == 'Computer') {
                        _this.selectedComputer = searchable.data;
                    }
                    if (searchable.type == 'GoogleUser') {
                        _this.selectedGoogleUser = searchable.data;
                    }
                    if (searchable.type == 'O365User') {
                        _this.selectedO365User = searchable.data;
                    }
                    if (searchable.type == 'DiskReport') {
                        _this.selectedDiskReport = searchable.data;
                    }
                };
                this.querySearch = function (search) {
                    if (!search) {
                        _this.noItemsMessage = 'Start typing the name of a user or computer to search site data...';
                        return [];
                    }
                    search = search.toLowerCase();
                    var results = [];
                    for (var _i = 0, _a = _this.searchables; _i < _a.length; _i++) {
                        var i = _a[_i];
                        if (i.search.indexOf(search) !== -1) {
                            results.push(i);
                        }
                        if (results.length >= 100) {
                            break;
                        }
                    }
                    return results;
                };
                if (!this.directoryService.currentDirectory)
                    return;
                if (!_.find(this.directoryService.currentDirectory.content, { name: 'data' })) {
                    return;
                }
                this.isSite = true;
                this.searchables = [];
                var basePath = this.directoryService.currentDirectory.fullname;
                var usersFile = {
                    name: 'users.json',
                    fullname: basePath + '/data/users.json',
                    exists: true,
                    extension: '.json',
                    type: 'File'
                };
                var computersFile = {
                    name: 'computers.json',
                    fullname: basePath + '/data/computers.json',
                    exists: true,
                    extension: '.json',
                    type: 'File'
                };
                var googleUsersFile = {
                    name: 'google-users.json',
                    fullname: basePath + '/data/google-users.json',
                    exists: true,
                    extension: '.json',
                    type: 'File'
                };
                var o365UsersFile = {
                    name: 'office365-users.json',
                    fullname: basePath + '/data/office365-users.json',
                    exists: true,
                    extension: '.json',
                    type: 'File'
                };
                var diskReports = {
                    name: 'DiskReports',
                    fullname: basePath + '/data/DiskReports',
                    type: 'Directory'
                };
                directoryService.loadFile(usersFile).then(function (fileContent) {
                    var users = (typeof fileContent.body == "object" ? fileContent.body : JSON.parse(fileContent.body));
                    var allUsers = [];
                    for (var _i = 0, users_1 = users; _i < users_1.length; _i++) {
                        var u = users_1[_i];
                        allUsers.push(_this.getSearchableUser(u));
                    }
                    _this.searchables = _.sortBy(_this.searchables.concat(allUsers), ['display']);
                }, function () { console.log(usersFile.fullname + " does not exist."); });
                directoryService.loadFile(computersFile).then(function (fileContent) {
                    var computers = (typeof fileContent.body == "object" ? fileContent.body : JSON.parse(fileContent.body));
                    var allComputers = [];
                    for (var _i = 0, computers_1 = computers; _i < computers_1.length; _i++) {
                        var u = computers_1[_i];
                        allComputers.push(_this.getSearchableComputer(u));
                    }
                    _this.searchables = _.sortBy(_this.searchables.concat(allComputers), ['display']);
                }, function () { console.log(computersFile.fullname + " does not exist."); });
                directoryService.loadFile(googleUsersFile).then(function (fileContent) {
                    var items = (typeof fileContent.body == "object" ? fileContent.body : JSON.parse(fileContent.body));
                    var allItems = [];
                    for (var _i = 0, items_1 = items; _i < items_1.length; _i++) {
                        var i = items_1[_i];
                        allItems.push(_this.getSearchableGoogleUser(i));
                    }
                    _this.searchables = _.sortBy(_this.searchables.concat(allItems), ['display']);
                }, function () { console.log(computersFile.fullname + " does not exist."); });
                directoryService.loadFile(o365UsersFile).then(function (fileContent) {
                    var items = (typeof fileContent.body == "object" ? fileContent.body : JSON.parse(fileContent.body));
                    var allItems = [];
                    for (var _i = 0, items_2 = items; _i < items_2.length; _i++) {
                        var i = items_2[_i];
                        allItems.push(_this.getSearchableOffice365User(i));
                    }
                    _this.searchables = _.sortBy(_this.searchables.concat(allItems), ['display']);
                }, function () { console.log(computersFile.fullname + " does not exist."); });
                directoryService.loadDirectory(diskReports).then(function (directory) {
                    if (directory.content) {
                        for (var _i = 0, _a = directory.content; _i < _a.length; _i++) {
                            var f = _a[_i];
                            if (f.type == 'File' && f.extension == '.json')
                                directoryService.loadFile(f).then(function (fileContent) {
                                    var raw = (typeof fileContent.body == "object" ? fileContent.body : JSON.parse(fileContent.body));
                                    _this.searchables.push(_this.getSearchableDiskReport(raw));
                                }, function () { console.log(f.fullname + " does not exist."); });
                        }
                    }
                }, function () { console.log(diskReports.fullname + " does not exist."); });
            }
            SiteController.prototype.getSearchableDiskReport = function (d) {
                return {
                    icon: "fa-database",
                    display: d.computername,
                    search: d.computername.toLowerCase(),
                    type: 'DiskReport',
                    data: d
                };
            };
            SiteController.prototype.getSearchableOffice365User = function (u) {
                return {
                    icon: 'fa-windows',
                    display: u.DisplayName,
                    search: u.DisplayName.toLowerCase(),
                    type: 'O365User',
                    data: u
                };
            };
            SiteController.prototype.getSearchableGoogleUser = function (u) {
                return {
                    icon: 'fa-google',
                    display: u.name.fullName,
                    search: u.name.fullName.toLowerCase(),
                    type: 'GoogleUser',
                    data: u
                };
            };
            SiteController.prototype.getSearchableUser = function (u) {
                return {
                    icon: 'fa-user',
                    display: u.name,
                    search: u.name.toLowerCase(),
                    type: 'User',
                    data: u
                };
            };
            SiteController.prototype.getSearchableComputer = function (c) {
                return {
                    icon: 'fa-server',
                    display: c.name,
                    search: (c.name + c.ipv4Address).toLowerCase(),
                    type: 'Computer',
                    data: c
                };
            };
            SiteController.$inject = ['app.giteditor.IGitDirectoryService'];
            return SiteController;
        }());
        var SiteDirective = (function () {
            function SiteDirective() {
                this.controllerAs = 'vm';
                this.controller = 'app.dp.SiteController';
                this.templateUrl = '/public/app/dp/site.html';
                this.restrict = 'E';
                this.scope = {};
            }
            SiteDirective.$inject = [];
            return SiteDirective;
        }());
        var UserDirective = (function () {
            function UserDirective() {
                this.templateUrl = '/public/app/dp/user.html';
                this.restrict = 'E';
                this.scope = {
                    user: '=user'
                };
            }
            UserDirective.$inject = [];
            return UserDirective;
        }());
        var GoogleUserDirective = (function () {
            function GoogleUserDirective() {
                this.templateUrl = '/public/app/dp/google-user.html';
                this.restrict = 'E';
                this.scope = {
                    user: '=user'
                };
            }
            GoogleUserDirective.$inject = [];
            return GoogleUserDirective;
        }());
        var Office365Directive = (function () {
            function Office365Directive() {
                this.templateUrl = '/public/app/dp/o365-user.html';
                this.restrict = 'E';
                this.scope = {
                    user: '=user'
                };
            }
            Office365Directive.$inject = [];
            return Office365Directive;
        }());
        var DiskReportDirective = (function () {
            function DiskReportDirective() {
                this.templateUrl = '/public/app/dp/diskreport.html';
                this.restrict = 'E';
                this.scope = {
                    report: '=report'
                };
            }
            DiskReportDirective.$inject = [];
            return DiskReportDirective;
        }());
        var AdComputerDirective = (function () {
            function AdComputerDirective() {
                this.templateUrl = '/public/app/dp/adcomputer.html';
                this.restrict = 'E';
                this.scope = {
                    computer: '=computer'
                };
            }
            AdComputerDirective.$inject = [];
            return AdComputerDirective;
        }());
        var slice = [].slice;
        function buildDirectiveFactory(ctor) {
            var factory;
            factory = function () {
                var args;
                args = 1 <= arguments.length ? slice.call(arguments, 0) : [];
                return (function (func, args, ctor) {
                    ctor.prototype = func.prototype;
                    var child = new ctor, result = func.apply(child, args);
                    return Object(result) === result ? result : child;
                })(ctor, args, function () { });
            };
            factory.$inject = ctor.$inject;
            return factory;
        }
        ;
        angular
            .module('app.dp')
            .directive('dpSite', buildDirectiveFactory(SiteDirective))
            .directive('dpUser', buildDirectiveFactory(UserDirective))
            .directive('dpGoogleUser', buildDirectiveFactory(GoogleUserDirective))
            .directive('dpO365User', buildDirectiveFactory(Office365Directive))
            .directive('dpAdComputer', buildDirectiveFactory(AdComputerDirective))
            .directive('dpDiskReport', buildDirectiveFactory(DiskReportDirective))
            .controller('app.dp.SiteController', SiteController);
    })(dp = app.dp || (app.dp = {}));
})(app || (app = {}));
