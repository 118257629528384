var app;
(function (app) {
    var giteditor;
    (function (giteditor) {
        'use strict';
        (function (DotSessionRenderType) {
            DotSessionRenderType[DotSessionRenderType["Live"] = 0] = "Live";
            DotSessionRenderType[DotSessionRenderType["Svg"] = 1] = "Svg";
            DotSessionRenderType[DotSessionRenderType["Png"] = 2] = "Png";
        })(giteditor.DotSessionRenderType || (giteditor.DotSessionRenderType = {}));
        var DotSessionRenderType = giteditor.DotSessionRenderType;
        var DotSession = (function () {
            function DotSession() {
            }
            return DotSession;
        }());
        var DotService = (function () {
            function DotService($q, $http, serverRoutes, cacheFactory) {
                var _this = this;
                this.$q = $q;
                this.$http = $http;
                this.serverRoutes = serverRoutes;
                this.cacheFactory = cacheFactory;
                this.tokenize = function (body) {
                    var session = {
                        content: body,
                        renderType: DotSessionRenderType.Live,
                        saveRendersToServer: false,
                        tokens: []
                    };
                    session.content = body.replace(/```dot((?:(?!```)[^])*)```/mg, function (whole, dot) {
                        var key = "zDPTOKEN" + session.tokens.length + "DPTOKENz";
                        session.tokens.push({
                            key: key,
                            dot: dot
                        });
                        return key;
                    });
                    return session;
                };
                this.substitute = function (session) {
                    switch (session.renderType) {
                        case DotSessionRenderType.Live:
                            for (var _i = 0, _a = session.tokens; _i < _a.length; _i++) {
                                var d = _a[_i];
                                try {
                                    var png = _this.render(d.dot, DotSessionRenderType.Png);
                                    session.content = session.content.replace(d.key, '<img src="' + png + '">');
                                }
                                catch (e) {
                                    session.content = session.content.replace(d.key, '<i class="fa fa-exclamation-triangle"></i> Syntax Error');
                                }
                            }
                            break;
                        case DotSessionRenderType.Png:
                            if (!session.documentPath)
                                throw new Error("DotService Render - documentPath required for png");
                            for (var i = 0; i < session.tokens.length; i++) {
                                var path = session.documentPath + '.dot.' + i + '.png';
                                session.content = session.content.replace(session.tokens[i].key, '<img src="' + path + '">');
                            }
                            break;
                        case DotSessionRenderType.Svg:
                            if (!session.documentPath)
                                throw new Error("DotService Render - documentPath required for svg");
                            for (var i = 0; i < session.tokens.length; i++) {
                                var path = session.documentPath + '.dot.' + i + '.svg';
                                session.content = session.content.replace(session.tokens[i].key, '<img src="' + path + '">');
                            }
                            break;
                        default:
                            throw new Error("DotService cannot substitute render type " + session.renderType);
                    }
                    return session;
                };
                this.render = function (dot, type) {
                    var format = '';
                    switch (type) {
                        case DotSessionRenderType.Png:
                            format = 'png-image-element';
                            break;
                        case DotSessionRenderType.Svg:
                            format = 'svg';
                            break;
                        default:
                            throw new Error("DotService unknown render type " + type);
                    }
                    var renderedDot = window.Viz(dot, { format: 'svg', engine: "dot" });
                    if (format === 'svg')
                        return renderedDot.match(/(<svg(?:(?!svg>)[^])*svg>)/gm)[0];
                    return 'data:image/svg+xml;base64,' + btoa(renderedDot);
                };
                this.save = function (session) {
                    debugger;
                    if (!session.documentPath)
                        throw new Error("DotService Save - documentPath required.");
                    var promises = [];
                    var _loop_1 = function(i) {
                        var pathPng = session.documentPath + '.dot.' + i + '.png';
                        var pathSvg = session.documentPath + '.dot.' + i + '.svg';
                        var svg = _this.render(session.tokens[i].dot, DotSessionRenderType.Svg);
                        var png = _this.render(session.tokens[i].dot, DotSessionRenderType.Png);
                        promises.push(_this.$http.post(_this.getFileUrl(pathSvg), svg));
                        var image = new Image();
                        var defer = _this.$q.defer();
                        image.onload = function (event) {
                            debugger;
                            var canvas = document.createElement('canvas');
                            var context = canvas.getContext('2d');
                            context.drawImage(image, 0, 0);
                            var datauri = canvas.toDataURL('image/png');
                            var binary = atob(datauri.substr('data:image/png;base64,'.length));
                            _this.$http.post(_this.getFileUrl(pathPng), binary).then(function (obj) {
                                defer.resolve(obj);
                            }).catch(function (e) { defer.reject(e); });
                        };
                        image.src = png;
                        promises.push(defer.promise);
                    };
                    for (var i = 0; i < session.tokens.length; i++) {
                        _loop_1(i);
                    }
                    return _this.$q.all(promises);
                };
                this.getFiles = function (session) {
                    var defer = _this.$q.defer();
                    debugger;
                    if (!session.documentPath)
                        throw new Error("DotService Save - documentPath required.");
                    var files = [];
                    var promises = [];
                    var _loop_2 = function(i) {
                        var pathPng = session.documentPath + '.dot.' + i + '.png';
                        var pathSvg = session.documentPath + '.dot.' + i + '.svg';
                        var svg = _this.render(session.tokens[i].dot, DotSessionRenderType.Svg);
                        var png = _this.render(session.tokens[i].dot, DotSessionRenderType.Png);
                        files.push({
                            metadata: {
                                fullname: pathSvg,
                                extension: '.svg',
                                type: 'File'
                            },
                            body: svg
                        });
                        var d = _this.$q.defer();
                        var image = new Image();
                        image.onload = function () {
                            var canvas = document.createElement('canvas');
                            canvas.width = image.width;
                            canvas.height = image.height;
                            var context = canvas.getContext('2d');
                            context.drawImage(image, 0, 0);
                            var datauri = canvas.toDataURL('image/png');
                            d.resolve({
                                metadata: {
                                    fullname: pathPng,
                                    extension: '.png',
                                    type: 'File'
                                },
                                body: _this.dataURLtoBlob(datauri)
                            });
                        };
                        image.src = png;
                        promises.push(d.promise);
                    };
                    for (var i = 0; i < session.tokens.length; i++) {
                        _loop_2(i);
                    }
                    return _this.$q.all(promises);
                };
            }
            DotService.prototype.dataURLtoBlob = function (dataurl) {
                var arr = dataurl.split(',');
                if (arr.length < 2) {
                    console.error("Not a dataurl " + dataurl);
                    return null;
                }
                var mime = arr[0].match(/:(.*?);/)[1], bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
                while (n--) {
                    u8arr[n] = bstr.charCodeAt(n);
                }
                return new Blob([u8arr], { type: mime });
            };
            DotService.prototype.getFileUrl = function (path) {
                return this.serverRoutes.git + "file/" + encodeURIComponent(path).replace('%2F', '/');
            };
            return DotService;
        }());
        function factory($q, $http, serverRoutes, cacheFactory) {
            return new DotService($q, $http, serverRoutes, cacheFactory);
        }
        angular
            .module('app.giteditor')
            .service('app.giteditor.IDotService', ['$q', '$http', 'IServerRoutes', 'CacheFactory', factory]);
    })(giteditor = app.giteditor || (app.giteditor = {}));
})(app || (app = {}));
