(function () {
    angular.module('app.giteditor').filter('filterSystem', function () {
        return function (input) {
            if (!input)
                return input;
            var filtered = [];
            for (var _i = 0, input_1 = input; _i < input_1.length; _i++) {
                var i = input_1[_i];
                if (i.name !== '.git') {
                    if (i.name.match(/.*\.dot\.[0-9]*\.(svg|png)$/))
                        i.name = "<span class='text-muted'>" + i.name + "</span>";
                    filtered.push(i);
                }
            }
            return filtered;
        };
    });
})();
