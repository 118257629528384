var app;
(function (app) {
    var giteditor;
    (function (giteditor) {
        'use strict';
        var RendererController = (function () {
            function RendererController($mdSidenav, $compile, $timeout, $sce, $scope, $http, $state, $stateParams, $mdToast, $mdDialog, gitDirectoryService, gitRenderService, gitEditorService, navService, hotkeys) {
                var _this = this;
                this.$mdSidenav = $mdSidenav;
                this.$compile = $compile;
                this.$timeout = $timeout;
                this.$sce = $sce;
                this.$scope = $scope;
                this.$http = $http;
                this.$state = $state;
                this.$stateParams = $stateParams;
                this.$mdToast = $mdToast;
                this.$mdDialog = $mdDialog;
                this.gitDirectoryService = gitDirectoryService;
                this.gitRenderService = gitRenderService;
                this.gitEditorService = gitEditorService;
                this.navService = navService;
                this.hotkeys = hotkeys;
                this.loading = true;
                this.forceRender = '';
                this.canEditMissingFileType = false;
                this.headerTemplate = '/SiteSettings/default.header.html';
                this.contentTemplate = '/SiteSettings/default.header.html';
                this.load = function () {
                    function gup(name, url) {
                        if (!url)
                            url = location.href;
                        name = name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
                        var regexS = "[\\?&]" + name + "=([^&#]*)";
                        var regex = new RegExp(regexS);
                        var results = regex.exec(url);
                        return results == null ? null : results[1];
                    }
                    _this.loading = true;
                    _this.file = _this.$state.get('browse-file').data.currentFile;
                    if (!_this.file) {
                        var path = _this.$stateParams['path'];
                        _this.gitDirectoryService.loadTree(path).then(function (file) {
                            _this.file = file;
                            if (_this.file.exists) {
                                _this.setup();
                            }
                            else {
                                _this.newFileSetup(path);
                            }
                        }, function (error) {
                            console.error(error);
                            _this.$mdToast.showSimple("Failed to load file information from the server.");
                        });
                    }
                    else {
                        _this.setup();
                    }
                };
                this.newFileSetup = function (path) {
                    var dirPath = path.substr(0, path.lastIndexOf('/')).replace(/^\/*/, '').replace(/\/*$/, '');
                    var currentDirectoryP = null;
                    if (!_this.gitDirectoryService.currentDirectory) {
                        currentDirectoryP = _this.gitDirectoryService.loadTree(dirPath);
                    }
                    else {
                        currentDirectoryP = Q(_this.gitDirectoryService.currentDirectory);
                    }
                    currentDirectoryP.then(function (currentDirectory) {
                        var ext = path.substr(path.lastIndexOf('.'));
                        _this.file = {
                            fullname: path,
                            name: path.substr(path.lastIndexOf('/')),
                            extension: ext,
                            parent: currentDirectory,
                            type: 'File',
                            exists: false
                        };
                        _this.canEditMissingFileType = _this.gitEditorService.canEdit(_this.file);
                        _this.loadedFile();
                    });
                };
                this.loadedFile = function () {
                    var addDiag = function ($event) {
                        var confirm = _this.$mdDialog.show({
                            controller: 'app.giteditor.NewDialogController',
                            controllerAs: 'vm',
                            templateUrl: '/public/app/giteditor/giteditor.new.html',
                            clickOutsideToClose: true
                        });
                    };
                    var menu = [
                        {
                            icon: 'mode_edit',
                            text: 'Edit',
                            uri: null,
                            action: function ($event) {
                                _this.edit();
                            }
                        },
                        {
                            icon: 'refresh',
                            text: 'Force Refresh',
                            uri: null,
                            action: function ($event) {
                                _this.gitDirectoryService.invalidateTree(_this.file.fullname);
                                _this.load();
                            }
                        },
                        {
                            icon: 'add',
                            text: 'New File / Folder',
                            uri: null,
                            action: addDiag
                        },
                        {
                            icon: 'history',
                            text: 'History',
                            uri: null,
                            action: _this.getHistory
                        }
                    ];
                    if (_this.file.exists) {
                        menu.unshift({
                            icon: 'file_download',
                            text: 'Download',
                            uri: null,
                            action: function ($event) {
                                _this.download();
                            }
                        });
                        if (_this.file.name !== "default.content.html") {
                            menu.unshift({
                                icon: 'textsms',
                                text: 'Rename',
                                uri: null,
                                action: _this.rename
                            });
                        }
                        menu.unshift({
                            icon: 'delete_forever',
                            text: 'Delete',
                            uri: null,
                            action: function ($event) {
                                _this.delete();
                            }
                        });
                    }
                    _this.navService.setContextMenu(menu);
                    _this.loading = false;
                    _this.hotkeys.bindTo(_this.$scope).add({
                        combo: 'e',
                        description: 'Edit File',
                        callback: function () {
                            _this.edit();
                        }
                    });
                    _this.hotkeys.bindTo(_this.$scope).add({
                        combo: 'd',
                        description: 'Delete File',
                        callback: function () {
                            _this.delete();
                        }
                    });
                    _this.hotkeys.bindTo(_this.$scope).add({
                        combo: 'n',
                        description: 'New Dialog',
                        callback: function () {
                            addDiag(_this);
                        }
                    });
                    _this.setTemplates();
                };
                this.loadedDirectory = function () {
                    _this.loading = false;
                    _this.isDirectory = true;
                    var menu = [
                        {
                            icon: 'add',
                            text: 'New File / Folder',
                            uri: null,
                            action: function ($event) {
                                var confirm = _this.$mdDialog.show({
                                    controller: 'app.giteditor.NewDialogController',
                                    controllerAs: 'vm',
                                    templateUrl: '/public/app/giteditor/giteditor.new.html',
                                    clickOutsideToClose: true
                                });
                            }
                        }
                    ];
                    menu.unshift({
                        icon: 'textsms',
                        text: 'Rename',
                        uri: null,
                        action: _this.rename
                    });
                    if (_this.file.content.length == 0) {
                        menu.unshift({
                            icon: 'delete_forever',
                            text: 'Delete',
                            uri: null,
                            action: function ($event) {
                                _this.delete();
                            }
                        });
                    }
                    _this.navService.setContextMenu(menu);
                    _this.hotkeys.bindTo(_this.$scope).add({
                        combo: 'd',
                        description: 'Delete Directory',
                        callback: function () {
                            _this.delete();
                        }
                    });
                    _this.setTemplates();
                };
                this.setup = function () {
                    if (_this.file.type === 'File') {
                        _this.isDirectory = false;
                        _this.renderer = _this.gitRenderService.getRenderer(_this.file);
                        if (_this.renderer.requiresContent) {
                            _this.gitDirectoryService.loadFile(_this.file).then(function (fileContent) {
                                _this.fullfile = fileContent;
                                _this.file = _this.fullfile.metadata;
                                _this.loadedFile();
                            });
                        }
                        else {
                            _this.loadedFile();
                        }
                    }
                    else {
                        _this.loadedDirectory();
                    }
                };
                this.setTemplates = function () {
                    _this.setHeaderTemplate();
                    _this.setContentTemplate();
                };
                this.setHeaderTemplate = function () {
                    if (!_this.gitDirectoryService.currentDirectory) {
                        _this.headerTemplate = '/SiteSettings/default.header.html';
                        return;
                    }
                    var header = _.find(_this.gitDirectoryService.currentDirectory.content, function (item) {
                        return item.name.toLowerCase() === 'header.html';
                    });
                    if (header)
                        _this.headerTemplate = header.fullname;
                    _this.headerTemplate = '/SiteSettings/default.header.html';
                };
                this.setContentTemplate = function () {
                    if (!_this.gitDirectoryService.currentDirectory) {
                        _this.contentTemplate = '/SiteSettings/default.content.html';
                        return;
                    }
                    var content = _.find(_this.gitDirectoryService.currentDirectory.content, function (item) {
                        return item.name.toLowerCase() === 'content.html' || item.name.toLowerCase() === 'content.md';
                    });
                    if (content)
                        _this.contentTemplate = content.fullname;
                    else
                        _this.contentTemplate = '/SiteSettings/default.content.html';
                };
                this.download = function () {
                    open(_this.navService.getFileUrlShim(_this.file.fullname));
                };
                this.edit = function () {
                    _this.gitDirectoryService.goEditFile(_this.file);
                };
                this.selectDirectoy = function (directory) {
                    if (_this.gitDirectoryService.currentDirectory && _this.gitDirectoryService.currentDirectory.fullname == directory.fullname)
                        return;
                    _this.loading = true;
                    _this.gitDirectoryService.goDirectory(directory);
                };
                this.toggleNav = function () {
                    _this.$mdSidenav('directoryNav').toggle();
                };
                this.delete = function () {
                    if (!_this.file)
                        return;
                    if (_this.file.type === 'File') {
                        _this.deleteFile(_this.file);
                    }
                    else {
                        _this.deleteDirectory(_this.file);
                    }
                };
                this.rename = function ($event) {
                    _this.$mdDialog.show({
                        controller: 'app.giteditor.RenameDialogController',
                        controllerAs: 'vm',
                        templateUrl: '/public/app/giteditor/giteditor.rename.html',
                        clickOutsideToClose: true
                    });
                };
                this.getHistory = function () {
                    _this.$mdDialog.show({
                        controller: 'app.giteditor.HistoryDialogController',
                        controllerAs: 'vm',
                        templateUrl: '/public/app/giteditor/giteditor.history.html',
                        clickOutsideToClose: true
                    });
                };
                hotkeys.bindTo($scope).add({
                    combo: 'h',
                    description: 'Home',
                    callback: function () {
                        _this.gitDirectoryService.goPath('/');
                    }
                });
                hotkeys.bindTo($scope).add({
                    combo: 'ctrl+up',
                    description: 'Navigate Up',
                    callback: function () {
                        _this.gitDirectoryService.goUp();
                    }
                });
                this.load();
            }
            RendererController.prototype.goUp = function () {
                this.gitDirectoryService.goUp();
            };
            RendererController.prototype.getBreadCrumbs = function () {
                if (!this.file)
                    return;
                var dir = null;
                dir = this.file;
                var crumbs = [];
                var d = dir;
                while (d != null && d.parent != null) {
                    crumbs.unshift(d);
                    d = d.parent;
                }
                return crumbs;
            };
            RendererController.prototype.deleteFile = function (file) {
                var _this = this;
                var confirm = this.$mdDialog.confirm()
                    .title('Confirm file deletion.')
                    .textContent('Deleting a file can only be undone by a manual process on the server. Are you sure you want to delete this file?')
                    .ariaLabel('Delete File Confirmation')
                    .ok('Delete')
                    .cancel('Cancel');
                this.$mdDialog.show(confirm).then(function () {
                    _this.gitDirectoryService.delete(file).then(function () { _this.$mdToast.showSimple("File Deleted"); _this.goUp(); }, function () { _this.$mdToast.showSimple("Delete Failed"); _this.goUp(); });
                }, function () { console.log("Delete canceled"); });
            };
            RendererController.prototype.deleteDirectory = function (directory) {
                var _this = this;
                if (directory.content && directory.content.length > 0) {
                    this.$mdToast.showSimple("Directories with content cannot be deleted.");
                    return;
                }
                var confirm = this.$mdDialog.confirm()
                    .title('Confirm directory deletion.')
                    .textContent('Are you sure you want to delete this directory?')
                    .ariaLabel('Delete File Confirmation')
                    .ok('Delete')
                    .cancel('Cancel');
                this.$mdDialog.show(confirm).then(function () {
                    _this.gitDirectoryService.delete(directory).then(function () { _this.$mdToast.showSimple("Directory Deleted"); _this.goUp(); }, function () { _this.$mdToast.showSimple("Delete Failed"); _this.goUp(); });
                }, function () { console.log("Delete canceled"); });
            };
            RendererController.$inject = ['$mdSidenav', '$compile', '$timeout', '$sce', '$scope', '$http', '$state', '$stateParams', '$mdToast', '$mdDialog', 'app.giteditor.IGitDirectoryService', 'app.giteditor.GitRenderService', 'app.giteditor.IGitEditorService', 'app.nav.INavService', 'hotkeys'];
            return RendererController;
        }());
        var RendererDirective = (function () {
            function RendererDirective() {
                this.templateUrl = '/public/app/giteditor/giteditor.renderer.html';
                this.controller = 'app.giteditor.RendererController';
                this.controllerAs = 'vm';
                this.restrict = 'E';
                this.scope = {};
            }
            return RendererDirective;
        }());
        var slice = [].slice;
        function buildDirectiveFactory(ctor) {
            var factory;
            factory = function () {
                var args;
                args = 1 <= arguments.length ? slice.call(arguments, 0) : [];
                return (function (func, args, ctor) {
                    ctor.prototype = func.prototype;
                    var child = new ctor, result = func.apply(child, args);
                    return Object(result) === result ? result : child;
                })(ctor, args, function () { });
            };
            factory.$inject = ctor.$inject;
            return factory;
        }
        ;
        angular
            .module('app.giteditor')
            .controller('app.giteditor.RendererController', RendererController)
            .directive('gitRenderer', buildDirectiveFactory(RendererDirective));
    })(giteditor = app.giteditor || (app.giteditor = {}));
})(app || (app = {}));
