var app;
(function (app) {
    var nav;
    (function (nav) {
        'use strict';
        var NavController = (function () {
            function NavController($scope, $location, auth, navService, directoryService, contextService, observeOnScope, $mdSidenav) {
                var _this = this;
                this.$scope = $scope;
                this.$location = $location;
                this.auth = auth;
                this.navService = navService;
                this.directoryService = directoryService;
                this.contextService = contextService;
                this.$mdSidenav = $mdSidenav;
                this.selectedDirection = 'left';
                this.selectedMode = 'md-scale';
                this.isOpen = false;
                this.show = true;
                this.canGoUp = false;
                this.go = function (fso) {
                    if (fso.type == 'File') {
                        _this.directoryService.goFile(fso);
                    }
                    else {
                        _this.directoryService.goDirectory(fso);
                    }
                };
                this.destination = function () {
                    if (!_this.selectedFileSystemObject)
                        return null;
                    if (_this.selectedFileSystemObject.type == 'File' && location.pathname.toLowerCase().startsWith('/edit')) {
                        return _this.selectedFileSystemObject;
                    }
                    if (_this.selectedFileSystemObject.type == 'File' && location.pathname.toLowerCase().startsWith('/browse')) {
                        return _this.selectedFileSystemObject.parent.parent;
                    }
                    return _this.selectedFileSystemObject.parent;
                };
                this.goUp = function () {
                    var d = _this.destination();
                    if (!d) {
                        console.log('cant nav to null... BUG!');
                        return;
                    }
                    if (d.type == 'File')
                        _this.directoryService.goFile(d);
                    else
                        _this.directoryService.goDirectory(d);
                };
                this.goHome = function () {
                    return _this.directoryService.goPath('/');
                };
                this.rightMenu = [
                    {
                        icon: 'exit_to_app',
                        text: 'Logout',
                        uri: '',
                        action: function () {
                            _this.auth.logout();
                        }
                    }
                ];
                this.leftMenu = [];
                this.mainMenuItems = [];
                this.css = function (m) {
                    if (m.uri && _this.$location.path() === m.uri.substr(1))
                        return 'md-primary';
                    return '';
                };
                this.action = function ($event, m) {
                    if (!!m.action) {
                        m.action($event);
                    }
                    else {
                        location.href = m.uri;
                    }
                };
                this.contextChanged = function (menu) {
                    _this.menuItems = _this.mainMenuItems.concat(menu);
                };
                this.toggleNav = function () {
                    _this.$mdSidenav('directoryNav').toggle();
                };
                this.path = this.$location.path();
                contextService
                    .getContext("selectedFilesystemObject")
                    .distinctUntilChanged()
                    .safeApply($scope, function (fso) {
                    _this.selectedFileSystemObject = fso;
                    _this.canGoUp = fso.parent != null;
                })
                    .subscribe();
                auth.logonChanged.on(function (value) {
                    console.log('LogonChanged EVENT: ' + value);
                    _this.show = value;
                });
                this.menuItems = this.mainMenuItems;
                navService.contextMenuChanged.on(this.contextChanged);
                $scope.$on('$destroy', function () {
                    _this.navService.contextMenuChanged.off(_this.contextChanged);
                });
            }
            NavController.$inject = ['$scope', '$location', 'app.services.IAuthService', 'app.nav.INavService', 'app.giteditor.IGitDirectoryService', 'app.context.IContextService', 'observeOnScope', '$mdSidenav'];
            return NavController;
        }());
        var MainNavDirective = (function () {
            function MainNavDirective() {
                this.templateUrl = '/public/app/nav/nav.html';
                this.controller = 'app.nav.NavController';
                this.controllerAs = 'vm';
                this.restrict = 'E';
                this.scope = {};
            }
            return MainNavDirective;
        }());
        var XsNavDirective = (function () {
            function XsNavDirective() {
                this.templateUrl = '/public/app/nav/xsnav.html';
                this.controller = 'app.nav.NavController';
                this.controllerAs = 'vm';
                this.restrict = 'E';
                this.scope = {};
            }
            return XsNavDirective;
        }());
        var slice = [].slice;
        function buildDirectiveFactory(ctor) {
            var factory;
            factory = function () {
                var args;
                args = 1 <= arguments.length ? slice.call(arguments, 0) : [];
                return (function (func, args, ctor) {
                    ctor.prototype = func.prototype;
                    var child = new ctor, result = func.apply(child, args);
                    return Object(result) === result ? result : child;
                })(ctor, args, function () { });
            };
            factory.$inject = ctor.$inject;
            return factory;
        }
        ;
        var NavService = (function () {
            function NavService() {
                var _this = this;
                this.contextMenuChanged = new LiteEvent();
                this.setContextMenu = function (menu) {
                    _this.currentContextMenu = menu;
                    _this.contextMenuChanged.trigger(menu);
                };
            }
            NavService.prototype.getFileUrlShim = function (url) {
                var root = document.querySelector('html').getAttribute("root");
                var trace = localStorage.getItem('trace') || false;
                url = url.toLowerCase();
                if (url.startsWith('/'))
                    url = url.slice(1);
                if (url.startsWith('browse') || url.startsWith('app') || url.startsWith('lib')) {
                    if (trace)
                        console.debug('url transform: ' + url + " => " + url);
                    return url;
                }
                if (url[0] != '/')
                    url = '/' + url;
                url = root + url;
                if (trace)
                    console.debug('url transform: ' + url + " => " + url);
                return url;
            };
            return NavService;
        }());
        function factory() {
            return new NavService();
        }
        angular
            .module('app.nav')
            .controller('app.nav.NavController', NavController)
            .service('app.nav.INavService', factory)
            .directive('mainNav', buildDirectiveFactory(MainNavDirective))
            .directive('xsNav', buildDirectiveFactory(XsNavDirective));
    })(nav = app.nav || (app.nav = {}));
})(app || (app = {}));
