(function () {
    'use strict';
    angular.module('app', [
        'ui.router',
        'ui.tree',
        'cfp.hotkeys',
        'app.core',
        'app.nav',
        'app.login',
        'app.services',
        'app.settings',
        'app.giteditor',
        'app.dp',
        'app.jira',
        'app.bpmn',
        'templates'
    ]);
})();
