var app;
(function (app) {
    var context;
    (function (context_1) {
        'use strict';
        var ContextService = (function () {
            function ContextService() {
                var _this = this;
                this.contextMap = {};
                this.contextObserverMap = {};
                this.contextCurrentValueMap = {};
                this.onNextContext = function (contextName, context) {
                    _this.getContext(contextName);
                    for (var _i = 0, _a = _this.contextObserverMap[contextName]; _i < _a.length; _i++) {
                        var l = _a[_i];
                        l.onNext(context);
                    }
                    _this.contextCurrentValueMap[contextName] = context;
                };
                window.context = this;
            }
            ContextService.prototype.getKeys = function () {
                return Object.keys(this.contextMap);
            };
            ContextService.prototype.getContext = function (contextName) {
                var _this = this;
                if (!this.contextMap.hasOwnProperty(contextName)) {
                    this.contextObserverMap[contextName] = [];
                    this.contextMap[contextName] = Rx.Observable.create(function (observer) {
                        if (!_this.contextObserverMap.hasOwnProperty(contextName))
                            _this.contextObserverMap[contextName] = [];
                        _this.contextObserverMap[contextName].push(observer);
                        if (_this.contextCurrentValueMap.hasOwnProperty(contextName))
                            observer.onNext(_this.contextCurrentValueMap[contextName]);
                    });
                }
                return this.contextMap[contextName];
            };
            ContextService.prototype.dispose = function (contextName, subscriber) {
                subscriber.dispose();
                this.getContext(contextName);
                this.contextObserverMap[contextName] = _.filter(this.contextObserverMap[contextName], { isStopped: false });
                var newValueMap = {};
                for (var key in this.contextObserverMap) {
                    if (this.contextCurrentValueMap.hasOwnProperty(key))
                        newValueMap[key] = this.contextCurrentValueMap[key];
                }
                this.contextCurrentValueMap = newValueMap;
            };
            return ContextService;
        }());
        angular
            .module('app.context')
            .service('app.context.IContextService', ['rx', ContextService]);
    })(context = app.context || (app.context = {}));
})(app || (app = {}));
