var app;
(function (app) {
    var giteditor;
    (function (giteditor) {
        var RenameDialogController = (function () {
            function RenameDialogController($http, $scope, $mdDialog, $mdToast, contextService, directoryService) {
                var _this = this;
                this.$http = $http;
                this.$scope = $scope;
                this.$mdDialog = $mdDialog;
                this.$mdToast = $mdToast;
                this.contextService = contextService;
                this.directoryService = directoryService;
                this.working = false;
                this.canRename = false;
                this.validate = function () {
                    var canRename = true;
                    if (_this.name.match('[\\:*?<>|]')) {
                        canRename = false;
                        _this.badCharsMessage = 'Name cannot include the following characters "\\:*?<>|"';
                    }
                    if (!_this.name) {
                        canRename = false;
                    }
                    _this.canRename = canRename;
                    return _this.canRename;
                };
                this.hide = function () {
                    _this.$mdDialog.hide();
                };
                this.cancel = function () {
                    _this.$mdDialog.hide();
                };
                this.rename = function () {
                    if (!_this.validate())
                        return;
                    _this.$http.post('api/git/rename', { oldName: _this.selectedFileSystemObject.fullname, newName: _this.name }).then(function (resp) {
                        if (resp.data === "true") {
                            _this.hide();
                            _this.directoryService.goPath(_this.name);
                            return;
                        }
                        _this.badCharsMessage = resp.data;
                    });
                };
                contextService
                    .getContext("selectedFilesystemObject")
                    .safeApply($scope, function (fso) {
                    _this.selectedFileSystemObject = fso;
                    _this.name = fso.fullname;
                })
                    .subscribe();
                $scope.$watch(angular.bind(this, function () {
                    return this.name;
                }), this.validate);
            }
            RenameDialogController.$inject = ['$http', '$scope', '$mdDialog', '$mdToast', 'app.context.IContextService', 'app.giteditor.IGitDirectoryService'];
            return RenameDialogController;
        }());
        giteditor.RenameDialogController = RenameDialogController;
        angular.module('app.giteditor')
            .controller('app.giteditor.RenameDialogController', RenameDialogController);
    })(giteditor = app.giteditor || (app.giteditor = {}));
})(app || (app = {}));
