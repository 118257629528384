var app;
(function (app) {
    var giteditor;
    (function (giteditor) {
        var CodeRenderer = (function () {
            function CodeRenderer($q, highlightService) {
                this.$q = $q;
                this.highlightService = highlightService;
                this.extensions = ['.txt', '.css', '.cs', '.ps1', '.psm1', '.js', '.csv', '.sh', '.yml', '.csr', '.jsx'];
                this.requiresContent = true;
            }
            CodeRenderer.prototype.render = function (content) {
                if (!content.body || content.body.trim() === '')
                    return {
                        isAsync: false,
                        immidate: '<br><br><br><br><i class="fa fa-question-circle"></i><span>This file is empty<span>',
                        eventual: this.$q.reject(false)
                    };
                return {
                    isAsync: true,
                    immidate: content.body,
                    eventual: this.highlightService.highlight(content.body)
                };
            };
            CodeRenderer.prototype.renderFile = function (content) { throw new Error("Not implemented"); };
            return CodeRenderer;
        }());
        giteditor.CodeRenderer = CodeRenderer;
        factory.$inject = [];
        function factory($q, hs) {
            return new CodeRenderer($q, hs);
        }
        angular
            .module('app.giteditor')
            .service('app.giteditor.renderersCodeRenderer', ['$q', 'app.giteditor.IHighlighterService', factory]);
    })(giteditor = app.giteditor || (app.giteditor = {}));
})(app || (app = {}));
