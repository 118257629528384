var SettingsService = (function () {
    function SettingsService() {
        this.setUsername = function (username) {
            SettingsService.username = username.toLowerCase();
        };
        this.getUsername = function () {
            if (!SettingsService.username && localStorage.getItem('wopr-username'))
                SettingsService.username = localStorage.getItem('wopr-username');
            return SettingsService.username;
        };
    }
    SettingsService.cache = null;
    return SettingsService;
}());
(function () {
    'use strict';
    angular.module('app.settings')
        .factory('app.settings.ISettingsService', function () { return new SettingsService(); });
})();
