var app;
(function (app) {
    var dp;
    (function (dp) {
        var DpLink = (function () {
            function DpLink($http, $mdToast) {
                var _this = this;
                this.$http = $http;
                this.$mdToast = $mdToast;
                this.restrict = 'A';
                this.link = function (scope, element, attributes) {
                    element.bind('click', function () {
                        if (attributes.hasOwnProperty('dpAjax')) {
                            _this.$http.get(attributes['dpLink']).then(function (transport) {
                                if (attributes.hasOwnProperty('dpAjaxSuccess')) {
                                    _this.$mdToast.showSimple(attributes['dpAjaxSuccess']);
                                }
                                else {
                                    _this.$mdToast.showSimple('OK');
                                }
                            }, function (error) {
                                if (attributes.hasOwnProperty('dpAjaxError')) {
                                    _this.$mdToast.showSimple(attributes['dpAjaxError']);
                                }
                                else {
                                    _this.$mdToast.showSimple('Failed');
                                }
                            });
                        }
                        else if (attributes.hasOwnProperty('dpNewWindow')) {
                            window.open(attributes['dpLink']);
                        }
                        else {
                            location = attributes['dpLink'];
                        }
                    });
                };
            }
            DpLink.$inject = ['$http', '$mdToast'];
            return DpLink;
        }());
        var slice = [].slice;
        function buildDirectiveFactory(ctor) {
            var factory;
            factory = function () {
                var args;
                args = 1 <= arguments.length ? slice.call(arguments, 0) : [];
                return (function (func, args, ctor) {
                    ctor.prototype = func.prototype;
                    var child = new ctor, result = func.apply(child, args);
                    return Object(result) === result ? result : child;
                })(ctor, args, function () { });
            };
            factory.$inject = ctor.$inject;
            return factory;
        }
        ;
        angular
            .module('app.dp')
            .directive('dpLink', buildDirectiveFactory(DpLink));
    })(dp = app.dp || (app.dp = {}));
})(app || (app = {}));
