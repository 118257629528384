var app;
(function (app) {
    var giteditor;
    (function (giteditor) {
        'use strict';
        var EditorController = (function () {
            function EditorController($q, $scope, $timeout, $http, $state, $stateParams, $mdToast, gitDirectoryService, gitEditorService, navService, hotkeys) {
                var _this = this;
                this.$q = $q;
                this.$scope = $scope;
                this.$timeout = $timeout;
                this.$http = $http;
                this.$state = $state;
                this.$stateParams = $stateParams;
                this.$mdToast = $mdToast;
                this.gitDirectoryService = gitDirectoryService;
                this.gitEditorService = gitEditorService;
                this.navService = navService;
                this.loading = true;
                this.newFileSetup = function (path) {
                    var dirPath = path.substr(0, path.lastIndexOf('/')).replace(/^\/*/, '').replace(/\/*$/, '');
                    var currentDirectoryP = null;
                    if (!_this.gitDirectoryService.currentDirectory) {
                        currentDirectoryP = _this.gitDirectoryService.loadTree(dirPath);
                    }
                    else {
                        currentDirectoryP = _this.$q.when(_this.gitDirectoryService.currentDirectory);
                    }
                    currentDirectoryP.then(function (currentDirectory) {
                        var ext = path.substr(path.lastIndexOf('.'));
                        _this.file = {
                            fullname: path,
                            name: path.substr(path.lastIndexOf('/') + 1),
                            extension: ext,
                            parent: _this.gitDirectoryService.currentDirectory,
                            exists: false,
                            type: 'File'
                        };
                        if (!_this.gitEditorService.canEdit(_this.file)) {
                            console.error("Cannot create file with ext " + ext);
                            var toast = _this.$mdToast.simple()
                                .hideDelay(0)
                                .textContent('Cannot create files with extension ' + ext + ' Please upload the file to the folder instead.')
                                .action('OK')
                                .highlightAction(true);
                            _this.$mdToast.show(toast).then(function (response) {
                                if (response == 'ok') {
                                    _this.gitDirectoryService.goDirectory(currentDirectory);
                                }
                            });
                            return;
                        }
                        _this.$state.get('edit-file').data.currentFile = _this.file;
                        _this.$state.get('edit-file').data.newFile = true;
                        _this.loaded();
                    }, function () {
                        console.error('failed to load directory info');
                    });
                };
                this.loaded = function () {
                    if (_this.file.type != "File") {
                        console.log(_this.file);
                    }
                    _this.navService.setContextMenu([
                        {
                            icon: 'file_download',
                            text: 'Download',
                            uri: null,
                            action: function ($event) {
                                _this.download();
                            }
                        },
                        {
                            icon: 'pageview',
                            text: 'View',
                            uri: null,
                            action: function ($event) {
                                _this.view();
                            }
                        }
                    ]);
                    _this.loading = false;
                };
                this.getEditorView = function () {
                    return _this.gitEditorService.editorView(_this.file);
                };
                this.getEditorController = function () {
                    return _this.gitEditorService.editorController(_this.file);
                };
                this.download = function () {
                    open(_this.navService.getFileUrlShim(_this.file.fullname));
                };
                this.view = function () {
                    _this.gitDirectoryService.goFile(_this.file);
                };
                hotkeys.bindTo($scope).add({
                    combo: 'h',
                    description: 'Home',
                    callback: function () {
                        _this.gitDirectoryService.goPath('/');
                    }
                });
                hotkeys.bindTo($scope).add({
                    combo: 'ctrl+up',
                    description: 'Navigate Up',
                    callback: function () {
                        _this.gitDirectoryService.goUp();
                    }
                });
                this.loading = true;
                this.file = this.$state.get('edit-file').data.currentFile;
                if (!this.file) {
                    this.gitDirectoryService.loadTree(this.$stateParams['path']).then(function (file) {
                        _this.file = file;
                        if (_this.file.exists) {
                            _this.$state.get('edit-file').data.currentFile = file;
                            _this.loaded();
                        }
                        else {
                            _this.newFileSetup(_this.$stateParams['path']);
                        }
                    }, function (error) {
                        console.error(error);
                        _this.$mdToast.showSimple("Failed to load file information from the server.");
                    });
                }
                else {
                    this.loaded();
                }
            }
            EditorController.prototype.goUp = function () {
                this.view();
            };
            EditorController.$inject = ['$q', '$scope', '$timeout', '$http', '$state', '$stateParams', '$mdToast', 'app.giteditor.IGitDirectoryService', 'app.giteditor.IGitEditorService', 'app.nav.INavService', 'hotkeys'];
            return EditorController;
        }());
        angular
            .module('app.giteditor')
            .controller('app.giteditor.EditorController', EditorController);
    })(giteditor = app.giteditor || (app.giteditor = {}));
})(app || (app = {}));
