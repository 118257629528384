var app;
(function (app) {
    var giteditor;
    (function (giteditor) {
        'use strict';
        (function (FlowSessionRenderType) {
            FlowSessionRenderType[FlowSessionRenderType["Live"] = 0] = "Live";
            FlowSessionRenderType[FlowSessionRenderType["Svg"] = 1] = "Svg";
        })(giteditor.FlowSessionRenderType || (giteditor.FlowSessionRenderType = {}));
        var FlowSessionRenderType = giteditor.FlowSessionRenderType;
        var FlowSession = (function () {
            function FlowSession() {
            }
            return FlowSession;
        }());
        var FlowService = (function () {
            function FlowService($http, serverRoutes, cacheFactory) {
                var _this = this;
                this.$http = $http;
                this.serverRoutes = serverRoutes;
                this.cacheFactory = cacheFactory;
                this.tokenize = function (body) {
                    var session = {
                        content: body,
                        renderType: FlowSessionRenderType.Live,
                        saveRendersToServer: false,
                        tokens: []
                    };
                    session.content = body.replace(/```flow((?:(?!```)[^])*)```/mg, function (whole, dot) {
                        var key = "zDPTOKEN-flow" + session.tokens.length + "flow-DPTOKENz";
                        session.tokens.push({
                            key: key,
                            dot: dot
                        });
                        return key;
                    });
                    return session;
                };
                this.substitute = function (session) {
                    switch (session.renderType) {
                        case FlowSessionRenderType.Live:
                            for (var _i = 0, _a = session.tokens; _i < _a.length; _i++) {
                                var d = _a[_i];
                                try {
                                    var svg = _this.render(d.dot, FlowSessionRenderType.Svg);
                                    session.content = session.content.replace('<p>' + d.key + '</p>', svg);
                                }
                                catch (e) {
                                    session.content = session.content.replace(d.key, '<i class="fa fa-exclamation-triangle"></i> Syntax Error');
                                }
                            }
                            break;
                        default:
                            throw new Error("FlowService cannot substitute render type " + session.renderType);
                    }
                    return session;
                };
                this.render = function (flow, type) {
                    var format = '';
                    switch (type) {
                        case FlowSessionRenderType.Svg:
                            format = 'svg';
                            break;
                        default:
                            throw new Error("FlowService unknown render type " + type);
                    }
                    var options = {
                        'x': 0,
                        'y': 0,
                        'line-width': 2,
                        'line-length': 50,
                        'text-margin': 10,
                        'font-size': 10,
                        'font-color': 'black',
                        'line-color': 'black',
                        'element-color': 'black',
                        'fill': 'white',
                        'yes-text': 'yes',
                        'no-text': 'no',
                        'arrow-end': 'block',
                        'scale': 1,
                        'symbols': {
                            'start': {
                                'font-color': 'red',
                                'element-color': 'green',
                                'fill': 'yellow'
                            },
                            'end': {
                                'class': 'end-element'
                            }
                        },
                        'flowstate': {
                            'past': { 'fill': '#CCCCCC', 'font-size': 12 },
                            'current': { 'fill': 'yellow', 'font-color': 'red', 'font-weight': 'bold' },
                            'future': { 'fill': '#FFFF99' },
                            'request': { 'fill': 'blue' },
                            'invalid': { 'fill': '#444444' },
                            'approved': { 'fill': '#58C4A3', 'font-size': 12, 'yes-text': 'APPROVED', 'no-text': 'n/a' },
                            'rejected': { 'fill': '#C45879', 'font-size': 12, 'yes-text': 'n/a', 'no-text': 'REJECTED' }
                        }
                    };
                    var $svg = $('<div></div>');
                    var doc = window.flowchart.parse(flow);
                    doc.drawSVG($svg[0], options);
                    return $svg.html();
                };
                this.save = function (session) {
                    return null;
                };
                this.getFiles = function (session) {
                    return null;
                };
            }
            FlowService.prototype.dataURLtoBlob = function (dataurl) {
                var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1], bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
                while (n--) {
                    u8arr[n] = bstr.charCodeAt(n);
                }
                return new Blob([u8arr], { type: mime });
            };
            FlowService.prototype.getFileUrl = function (path) {
                return this.serverRoutes.git + "file/" + encodeURIComponent(path).replace('%2F', '/');
            };
            return FlowService;
        }());
        function factory($http, serverRoutes, cacheFactory) {
            return new FlowService($http, serverRoutes, cacheFactory);
        }
        angular
            .module('app.giteditor')
            .service('app.giteditor.IFlowService', ['$http', 'IServerRoutes', 'CacheFactory', factory]);
    })(giteditor = app.giteditor || (app.giteditor = {}));
})(app || (app = {}));
