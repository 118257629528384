var app;
(function (app) {
    var giteditor;
    (function (giteditor) {
        'use strict';
        var AceSidebySideEditorController = (function () {
            function AceSidebySideEditorController($http, $q, $rootScope, $location, $timeout, $state, $stateParams, $mdToast, $mdDialog, $mdSidenav, gitDirectoryService, gitRenderService, dotService, hotkeys) {
                var _this = this;
                this.$http = $http;
                this.$q = $q;
                this.$rootScope = $rootScope;
                this.$location = $location;
                this.$timeout = $timeout;
                this.$state = $state;
                this.$stateParams = $stateParams;
                this.$mdToast = $mdToast;
                this.$mdDialog = $mdDialog;
                this.$mdSidenav = $mdSidenav;
                this.gitDirectoryService = gitDirectoryService;
                this.gitRenderService = gitRenderService;
                this.dotService = dotService;
                this.hotkeys = hotkeys;
                this.html = '';
                this.loading = true;
                this.mode = 'markdown';
                this.commitMessage = '';
                this.previewEnabled = true;
                this.aceMode = true;
                this.expMode = localStorage.getItem('expMode') || false;
                this.theme = localStorage.getItem('theme') ? JSON.parse(localStorage.getItem('theme')).ace : 'chrome';
                this.xmlMode = false;
                this.xmlValidatorUrl = localStorage.getItem('xmlValidatorUrl') || "https://folio.dynamicplatform.com";
                this.xmlAutoValidate = true;
                this.xmlValidateStatus = "Initializing";
                this.xmlValidateNow = function () {
                    localStorage.setItem('xmlValidatorUrl', _this.xmlValidatorUrl);
                    _this.xmlValidate();
                };
                this.xmlErrors = [];
                this.xmlValidate = function () {
                    _this.xmlValidateStatus = "";
                    _this.xmlIsValidating = true;
                    if (!_this.xmlMode)
                        return;
                    _this.$http.post(_this.xmlValidatorUrl + "/api/validate", { xmlString: _this.fileContent.body }).then(function (transport) {
                        if (transport.status != 200) {
                            console.error(transport.statusText, transport);
                            _this.xmlValidateStatus = "Connection Error";
                        }
                        else {
                            var result = transport.data;
                            _this.xmlIsValid = result.isValid;
                            _this.xmlErrors = [];
                            if (result.errors)
                                for (var _i = 0, _a = result.errors; _i < _a.length; _i++) {
                                    var e = _a[_i];
                                    _this.xmlErrors.push({
                                        row: e.lineNumber,
                                        column: e.linePosition,
                                        text: e.message,
                                        type: e.level === "Warning" ? "warning" : "error"
                                    });
                                }
                            _this.$timeout(function () {
                                console.log(_this.xmlErrors);
                                if (_this.xmlErrors.length > 0) {
                                    var errors = _this.editor.session.getAnnotations() || [];
                                    _this.editor.session.setAnnotations(errors.concat(_this.xmlErrors));
                                }
                            }, 250);
                        }
                        _this.xmlIsValidating = false;
                    }).catch(function (e) {
                        console.error(e);
                        _this.xmlValidateStatus = "Connection Error";
                    });
                };
                this.xmlIsValidating = true;
                this.xmlIsValid = false;
                this.isOpenRight = function () {
                    return _this.$mdSidenav('AceSideBySideRightBar').isOpen();
                };
                this.togglePreview = function () {
                    _this.$mdSidenav('AceSideBySideRightBar').toggle();
                };
                this.aceLoaded = function (editor) {
                    _this.editor = editor;
                    _this.editor.setOptions({
                        enableBasicAutocompletion: true,
                        enableSnippets: false,
                        enableLiveAutocompletion: true,
                        useElasticTabstops: true
                    });
                    _this.editor.on("changeAnnotation", console.debug);
                    _this.setMode();
                    _this.aceChanged();
                };
                this.aceChangedDebounced = function () {
                    _this.renderPreview();
                    if (_this.xmlAutoValidate)
                        _this.xmlValidate();
                };
                this.aceChanged = _.debounce(this.aceChangedDebounced, 300, { 'maxWait': 1000 });
                this.renderPreview = function () {
                    if (!_this.previewEnabled)
                        return;
                    var result;
                    if (_this.file.extension === '.md') {
                        var renderer = _this.gitRenderService.getRenderer(_this.file);
                        renderer.dotRenderMode = giteditor.MdDotRenderMode.Live;
                        result = renderer.render(_this.fileContent);
                    }
                    else
                        result = _this.gitRenderService.render(_this.fileContent);
                    _this.html = result.immidate;
                    if (result.isAsync) {
                        result.eventual.then(function (html) {
                            _this.html = html;
                        });
                    }
                };
                this.setContent = function (content) {
                    if (_this.file.extension == '.json' && typeof content.body == "object")
                        content.body = JSON.stringify(content.body, null, 2);
                    _this.fileContent = content;
                    _this.originalContent = content.body;
                    _this.setMode();
                };
                this.load = function () {
                    _this.loading = true;
                    if (_this.$state.get('edit-file').data.newFile) {
                        _this.$state.get('edit-file').data.newFile = false;
                        _this.$mdToast.showSimple('This file does not exist in the repository yet. Saving will create it.');
                        _this.setContent({ metadata: _this.file, body: '' });
                        _this.loaded();
                    }
                    else {
                        _this.gitDirectoryService.loadFile(_this.file).then(function (fileContent) {
                            _this.setContent(fileContent);
                            _this.loaded();
                        });
                    }
                };
                this.loaded = function () {
                    _this.loading = false;
                    var stateChangeStartSubscription = null;
                    var locationChangeStartSubscription = null;
                    function unsub() {
                        if (stateChangeStartSubscription)
                            stateChangeStartSubscription();
                        if (locationChangeStartSubscription)
                            locationChangeStartSubscription();
                    }
                    locationChangeStartSubscription = _this.$rootScope.$on('$locationChangeStart', function (event, newUrl, oldUrl) {
                        if (!_this.editor)
                            return;
                        if (_this.originalContent === _this.editor.getSession().getValue()) {
                            unsub();
                            return;
                        }
                        event.preventDefault();
                        var confirm = _this.$mdDialog.confirm()
                            .title('Leave page without saving?')
                            .ariaLabel('Leave page without saving?')
                            .ok('No')
                            .cancel('Discard Changes');
                        _this.$mdDialog.show(confirm).then(function () {
                        }, function () {
                            unsub();
                            _this.$location.path(newUrl);
                        });
                    });
                    stateChangeStartSubscription = _this.$rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
                        if (!_this.editor)
                            return;
                        if (_this.originalContent === _this.editor.getSession().getValue()) {
                            unsub();
                            return;
                        }
                        event.preventDefault();
                        var confirm = _this.$mdDialog.confirm()
                            .title('Leave page without saving?')
                            .ariaLabel('Leave page without saving?')
                            .ok('No')
                            .cancel('Discard Changes');
                        _this.$mdDialog.show(confirm).then(function () {
                        }, function () {
                            unsub();
                            _this.$state.transitionTo(toState, toParams);
                        });
                    });
                };
                this.find = function () {
                    _this.editor.execCommand("find");
                };
                this.beautify = function () {
                    if (_this.editor) {
                        var beautify = window.ace.require("ace/ext/beautify");
                        beautify.beautify(_this.editor.session);
                    }
                };
                this.save = function () {
                    _this.simpleSave();
                };
                this.commit = function () {
                    var files = [_this.fileContent];
                    if (!_this.commitMessage) {
                        _this.$mdToast.showSimple("Commit Message is Required");
                        angular.element('#AceSideBySideCommitMessage').focus();
                        return;
                    }
                    _this.loading = true;
                    if (_this.file.extension == '.md') {
                        var session = _this.dotService.tokenize(_this.fileContent.body);
                        session.documentPath = _this.file.fullname;
                        files = _this.dotService.getFiles(session).then(function (otherFiles) {
                            return [_this.fileContent].concat(otherFiles);
                        });
                    }
                    _this.$q.when(files).then(function (resolvedfiles) {
                        _this.gitDirectoryService.commitFile(_this.commitMessage, resolvedfiles).then(function (data) {
                            _this.originalContent = _this.fileContent.body;
                            _this.commitMessage = '';
                            _this.$mdToast.showSimple("Commit Successful");
                        }, function (error) {
                            console.error(error);
                            _this.commitMessage = '';
                            _this.$mdToast.showSimple("Commit Failed: " + error);
                        }).then(function () {
                            _this.loading = false;
                        });
                    });
                };
                this.simpleSave = function () {
                    _this.gitDirectoryService.saveSimpleFile(_this.file.fullname, _this.fileContent).then(function (data) {
                        _this.originalContent = _this.fileContent.body;
                        _this.commitMessage = '';
                        _this.$mdToast.showSimple("Save Successful");
                    }, function (error) {
                        console.error(error);
                        _this.commitMessage = '';
                        _this.$mdToast.showSimple("Save Failed");
                    }).then(function () {
                        _this.loading = false;
                    });
                };
                this.revert = function () {
                    _this.load();
                };
                this.view = "/public/app/giteditor/editors/aceSideBySide.html";
                this.extensions = ['.md', '.txt', '.svg', '.html', '.json', '.css', '.cs', '.ps1', '.psm1', '.js', '.csv', '.sh', '.yml', '.jsx', '.xml', 'psd1'];
                hotkeys.bindTo($rootScope).add({
                    combo: 's',
                    description: 'Save / Commits edit',
                    callback: function () {
                        _this.commitMessage = _this.commitMessage || 'save';
                        _this.save();
                    }
                });
                hotkeys.bindTo($rootScope).add({
                    combo: 'ctrl+s',
                    description: 'Save',
                    callback: function () {
                        event.preventDefault();
                        _this.$mdToast.showSimple('Saving... (not committing)');
                        _this.simpleSave();
                    }
                });
                this.loading = true;
                this.file = this.$state.get('edit-file').data.currentFile;
                if (!this.file) {
                    return;
                }
                this.load();
            }
            AceSidebySideEditorController.prototype.setMode = function () {
                this.xmlMode = false;
                if (!this.file)
                    return;
                switch (this.file.extension) {
                    case '.xml':
                    case '.svg':
                        this.mode = 'xml';
                        this.xmlMode = true;
                        break;
                    case '.md':
                        this.mode = 'markdown';
                        break;
                    case '.json':
                        this.mode = 'json';
                        break;
                    case '.js':
                        this.mode = 'javascript';
                        break;
                    case '.jsx':
                        this.mode = 'jsx';
                        break;
                    case '.html':
                        this.mode = 'html';
                        break;
                    case '.css':
                        this.mode = 'css';
                        break;
                    case '.ps1':
                    case '.psm1':
                    case '.psd1':
                        this.mode = 'powershell';
                        break;
                    default:
                        this.mode = 'text';
                        break;
                }
                ;
            };
            AceSidebySideEditorController.prototype.keydown = function ($event) {
                if ($event.originalEvent.keyCode == 13) {
                    this.save();
                }
            };
            AceSidebySideEditorController.$inject = ['$http', '$q', '$rootScope', '$location', '$timeout', '$state', '$stateParams', '$mdToast',
                '$mdDialog', '$mdSidenav', 'app.giteditor.IGitDirectoryService', 'app.giteditor.GitRenderService', 'app.giteditor.IDotService', 'hotkeys'];
            return AceSidebySideEditorController;
        }());
        giteditor.AceSidebySideEditorController = AceSidebySideEditorController;
        angular
            .module('app.giteditor')
            .controller('app.giteditor.editorsAceSidebySideEditorController', AceSidebySideEditorController);
    })(giteditor = app.giteditor || (app.giteditor = {}));
})(app || (app = {}));
