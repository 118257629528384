var app;
(function (app) {
    var giteditor;
    (function (giteditor) {
        var HistoryDialogController = (function () {
            function HistoryDialogController($http, $scope, $mdDialog, $mdToast, contextService, directoryService) {
                var _this = this;
                this.$http = $http;
                this.$scope = $scope;
                this.$mdDialog = $mdDialog;
                this.$mdToast = $mdToast;
                this.contextService = contextService;
                this.directoryService = directoryService;
                this.title = "History";
                this.working = false;
                this.error = function (error) {
                    _this.detail = "An error occured. " + error;
                    _this.working = false;
                };
                this.load = function () {
                    _this.$http.get('/api/git/history' + _this.selectedFileSystemObject.fullname).then(function (resp) {
                        _this.history = resp.data;
                        _this.working = false;
                    }).catch(_this.error);
                };
                this.hide = function () {
                    _this.$mdDialog.hide();
                };
                this.loadDetail = function (sha) {
                    _this.working = true;
                    _this.title = "History: " + sha;
                    _this.$http.post('/api/git/history-detail', { sha: sha, path: _this.selectedFileSystemObject.fullname }).then(function (resp) {
                        if (resp.data) {
                            _this.detail = resp.data;
                        }
                        else {
                            _this.detail = "This file was committed blank! (This is not the content of the file at this point in its history)";
                        }
                        _this.working = false;
                    }).catch(_this.error);
                };
                this.back = function () {
                    if (_this.detail === "") {
                        _this.hide();
                        return;
                    }
                    _this.detail = "";
                    _this.title = "History";
                };
                contextService
                    .getContext("selectedFilesystemObject")
                    .safeApply($scope, function (fso) {
                    _this.selectedFileSystemObject = fso;
                    _this.working = true;
                    _this.load();
                })
                    .subscribe();
            }
            HistoryDialogController.$inject = ['$http', '$scope', '$mdDialog', '$mdToast', 'app.context.IContextService', 'app.giteditor.IGitDirectoryService'];
            return HistoryDialogController;
        }());
        giteditor.HistoryDialogController = HistoryDialogController;
        angular.module('app.giteditor')
            .controller('app.giteditor.HistoryDialogController', HistoryDialogController);
    })(giteditor = app.giteditor || (app.giteditor = {}));
})(app || (app = {}));
