var app;
(function (app) {
    var giteditor;
    (function (giteditor) {
        var ImgRenderer = (function () {
            function ImgRenderer($q) {
                this.$q = $q;
                this.extensions = ['.jpg', '.jpeg', '.png', '.gif'];
            }
            ImgRenderer.prototype.render = function (content) { throw new Error("Not implemented"); };
            ImgRenderer.prototype.renderFile = function (file) {
                return {
                    isAsync: false,
                    immidate: '<a target="_blank" href="' + file.fullname + '"><img style="max-width: 100%; height: auto; max-height: calc(100vh - 175px); display: block" src="' + file.fullname + '"></img></a><span style="font-size: 10px;color: gray">Click on image to open it in a new tab</span>',
                    eventual: this.$q.reject(false)
                };
            };
            return ImgRenderer;
        }());
        giteditor.ImgRenderer = ImgRenderer;
        factory.$inject = [];
        function factory($q) {
            return new ImgRenderer($q);
        }
        angular
            .module('app.giteditor')
            .service('app.giteditor.renderersImgRenderer', ['$q', factory]);
    })(giteditor = app.giteditor || (app.giteditor = {}));
})(app || (app = {}));
var app;
(function (app) {
    var giteditor;
    (function (giteditor) {
        var SvgRenderer = (function () {
            function SvgRenderer($q) {
                this.$q = $q;
                this.extensions = ['.svg'];
                this.requiresContent = true;
            }
            SvgRenderer.prototype.render = function (content) {
                return {
                    isAsync: false,
                    immidate: '<img src="data:image/svg+xml;base64,' + btoa(content.body) + '">',
                    eventual: this.$q.reject(false)
                };
            };
            SvgRenderer.prototype.renderFile = function (file) {
                throw new Error("Not implemented");
            };
            return SvgRenderer;
        }());
        giteditor.SvgRenderer = SvgRenderer;
        factory.$inject = [];
        function factory($q) {
            return new SvgRenderer($q);
        }
        angular
            .module('app.giteditor')
            .service('app.giteditor.renderersSvgRenderer', ['$q', factory]);
    })(giteditor = app.giteditor || (app.giteditor = {}));
})(app || (app = {}));
