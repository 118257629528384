var app;
(function (app) {
    var giteditor;
    (function (giteditor) {
        (function (MdDotRenderMode) {
            MdDotRenderMode[MdDotRenderMode["None"] = 0] = "None";
            MdDotRenderMode[MdDotRenderMode["Live"] = 1] = "Live";
            MdDotRenderMode[MdDotRenderMode["Svg"] = 2] = "Svg";
            MdDotRenderMode[MdDotRenderMode["Png"] = 3] = "Png";
        })(giteditor.MdDotRenderMode || (giteditor.MdDotRenderMode = {}));
        var MdDotRenderMode = giteditor.MdDotRenderMode;
        var MarkdownRenderer = (function () {
            function MarkdownRenderer($q, marked, dotService, flowService) {
                this.$q = $q;
                this.marked = marked;
                this.dotService = dotService;
                this.flowService = flowService;
                this.extensions = ['.md'];
                this.dotRenderMode = MdDotRenderMode.Png;
                this.requiresContent = true;
            }
            MarkdownRenderer.prototype.render = function (content) {
                var _this = this;
                var qd = this.$q.defer();
                var contentString = content.body;
                setTimeout(function () {
                    if (_this.dotRenderMode === MdDotRenderMode.None) {
                        qd.resolve(_this.marked(content.body));
                        return;
                    }
                    try {
                        var dotSession = _this.dotService.tokenize(content.body);
                        dotSession.documentPath = content.metadata.fullname;
                        var flowSession = _this.flowService.tokenize(dotSession.content);
                        flowSession.documentPath = content.metadata.fullname;
                        dotSession.content = _this.marked(flowSession.content);
                        dotSession.renderType = _this.getRenderMode();
                        dotSession = _this.dotService.substitute(dotSession);
                        flowSession.content = dotSession.content;
                        flowSession.renderType = giteditor.FlowSessionRenderType.Live;
                        flowSession = _this.flowService.substitute(flowSession);
                        qd.resolve('<div class="markdown" data-filename="' + encodeURIComponent(content.metadata.name) + '">' + flowSession.content + '</div>');
                    }
                    catch (e) {
                        console.log("Markdown Renderer:", e);
                        qd.resolve('<strong>Syntax Error</strong><div class="markdown" data-filename="' + encodeURIComponent(content.metadata.name) + '">' + content.body + '</div>');
                    }
                }, 0);
                return {
                    isAsync: true,
                    immidate: "<pre class='raw-md'>" + contentString + "</pre>",
                    eventual: qd.promise
                };
            };
            MarkdownRenderer.prototype.renderFile = function (content) { throw new Error("Not implemented"); };
            MarkdownRenderer.prototype.getRenderMode = function () {
                switch (this.dotRenderMode) {
                    case MdDotRenderMode.Svg:
                        return giteditor.DotSessionRenderType.Svg;
                    case MdDotRenderMode.Png:
                        return giteditor.DotSessionRenderType.Png;
                    case MdDotRenderMode.Live:
                        return giteditor.DotSessionRenderType.Live;
                    default:
                        throw new Error("MarkdownRenderer - dotRenderMode " + this.dotRenderMode);
                }
            };
            return MarkdownRenderer;
        }());
        giteditor.MarkdownRenderer = MarkdownRenderer;
        function factory($q, dotService, flowService) {
            return new MarkdownRenderer($q, marked, dotService, flowService);
        }
        angular
            .module('app.giteditor')
            .service('app.giteditor.renderersMarkdownRenderer', ['$q', 'app.giteditor.IDotService', 'app.giteditor.IFlowService', factory]);
    })(giteditor = app.giteditor || (app.giteditor = {}));
})(app || (app = {}));
