var app;
(function (app) {
    var giteditor;
    (function (giteditor) {
        'use strict';
        var defaultHeaderPath = "/SiteSettings/default.directory.header.html";
        var GiteditorDirectoryNavController = (function () {
            function GiteditorDirectoryNavController($rootScope, $mdSidenav, $scope, $http, $timeout, $state, $stateParams, $mdDialog, gitDirectoryService, navService, iconService, contextService) {
                var _this = this;
                this.$rootScope = $rootScope;
                this.$mdSidenav = $mdSidenav;
                this.$scope = $scope;
                this.$http = $http;
                this.$timeout = $timeout;
                this.$state = $state;
                this.$stateParams = $stateParams;
                this.$mdDialog = $mdDialog;
                this.gitDirectoryService = gitDirectoryService;
                this.navService = navService;
                this.iconService = iconService;
                this.contextService = contextService;
                this.loading = true;
                this.magicRedererTemplate = '';
                this.header = defaultHeaderPath;
                this.loaded = function () {
                    var header = _.find(_this.currentDirectory.content, { name: 'directory.header.html' });
                    _this.header = header ? header.fullname : defaultHeaderPath;
                    if (!_this.currentDirectory.fullname || _this.currentDirectory.fullname == '/') {
                        _this.magicRedererTemplate = '/home.html';
                    }
                    else {
                        var index = _.find(_this.currentDirectory.content, { name: 'index.html' });
                        if (index) {
                            _this.magicRedererTemplate = index.fullname;
                        }
                        else {
                            _this.magicRedererTemplate = '';
                        }
                    }
                    _this.loading = false;
                };
                this.selectItem = function (item) {
                    if (item.type == 'File')
                        return _this.selectFile(item);
                    if (item.type == 'Directory')
                        return _this.selectDirectoy(item);
                    console.error("ERROR: selectItem called with strange item", item);
                };
                this.selectFile = function (file) {
                    if (_this.gitDirectoryService.currentFile && _this.gitDirectoryService.currentFile.fullname == file.fullname)
                        return;
                    _this.loading = true;
                    _this.gitDirectoryService.goFile(file);
                };
                this.selectDirectoy = function (directory) {
                    if (_this.gitDirectoryService.currentDirectory && _this.gitDirectoryService.currentDirectory.fullname == directory.fullname)
                        return;
                    _this.loading = true;
                    _this.gitDirectoryService.goDirectory(directory);
                };
                this.toggleNav = function () {
                    _this.$mdSidenav('directoryNav').toggle();
                };
                var subscriber = contextService
                    .getContext("selectedFilesystemObject")
                    .debounce(100)
                    .safeApply($scope, function (fso) {
                    if (fso.type == 'File')
                        _this.currentDirectory = fso.parent;
                    else
                        _this.currentDirectory = fso;
                    _this.loaded();
                })
                    .subscribe();
                $scope.$on("$destroy", function () {
                    contextService.dispose("selectedFilesystemObject", subscriber);
                });
            }
            GiteditorDirectoryNavController.prototype.load = function () {
            };
            GiteditorDirectoryNavController.prototype.getChildDirectories = function (directory) {
                if (!directory)
                    return [];
                var dirs = [];
                for (var _i = 0, _a = directory.content; _i < _a.length; _i++) {
                    var d = _a[_i];
                    if (d.type == 'Directory')
                        dirs.push(d);
                }
                return dirs;
            };
            GiteditorDirectoryNavController.prototype.getChildFiles = function (directory) {
                if (!directory)
                    return [];
                var files = [];
                for (var _i = 0, _a = directory.content; _i < _a.length; _i++) {
                    var f = _a[_i];
                    if (f.type == 'File')
                        files.push(f);
                }
                return files;
            };
            GiteditorDirectoryNavController.prototype.goUp = function () {
                this.gitDirectoryService.goUp();
            };
            GiteditorDirectoryNavController.prototype.goHome = function () {
                this.gitDirectoryService.goPath('/');
            };
            GiteditorDirectoryNavController.prototype.icon = function (item) {
                return this.iconService.getIcon(item);
            };
            GiteditorDirectoryNavController.$inject = ['$rootScope', '$mdSidenav', '$scope', '$http', '$timeout', '$state', '$stateParams', '$mdDialog', 'app.giteditor.IGitDirectoryService', 'app.nav.INavService', 'app.giteditor.IFileSystemIconService', 'app.context.IContextService'];
            return GiteditorDirectoryNavController;
        }());
        var GiteditorDirectoryNavDirective = (function () {
            function GiteditorDirectoryNavDirective() {
                this.templateUrl = '/public/app/giteditor/giteditor.directorynav.html';
                this.controller = 'app.giteditor.GiteditorDirectoryNavController';
                this.controllerAs = 'vm';
                this.restrict = 'E';
                this.scope = {};
            }
            return GiteditorDirectoryNavDirective;
        }());
        var slice = [].slice;
        function buildDirectiveFactory(ctor) {
            var factory;
            factory = function () {
                var args;
                args = 1 <= arguments.length ? slice.call(arguments, 0) : [];
                return (function (func, args, ctor) {
                    ctor.prototype = func.prototype;
                    var child = new ctor, result = func.apply(child, args);
                    return Object(result) === result ? result : child;
                })(ctor, args, function () { });
            };
            factory.$inject = ctor.$inject;
            return factory;
        }
        ;
        angular
            .module('app.giteditor')
            .controller('app.giteditor.GiteditorDirectoryNavController', GiteditorDirectoryNavController)
            .directive('gitNav', buildDirectiveFactory(GiteditorDirectoryNavDirective));
    })(giteditor = app.giteditor || (app.giteditor = {}));
})(app || (app = {}));
