(function () {
    moment.locale();
    angular.module('app').filter('bytes', function () {
        return function (bytes, precision) {
            if (isNaN(parseFloat(bytes)) || !isFinite(bytes))
                return '-';
            if (bytes == 0)
                return '0 bytes';
            if (typeof precision === 'undefined')
                precision = 1;
            var units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB'], number = Math.floor(Math.log(bytes) / Math.log(1024));
            return (bytes / Math.pow(1024, Math.floor(number))).toFixed(precision) + ' ' + units[number];
        };
    });
    angular.module('app').filter('jsonDate', function () {
        return function (dateStr) {
            if (dateStr && dateStr.match(/\/Date\([0-9]+\)\//)) {
                try {
                    var d = new Date(parseInt(dateStr.substr(6, dateStr.length - 8)));
                    return moment(d).format('LLLL') + ' ' + moment(d).fromNow();
                }
                catch (error) {
                    return '<span class="bad-date">bad date</span>';
                }
            }
            return '';
        };
    });
    angular.module('app').filter('momentAgo', function () {
        return function (dateStr) {
            if (!dateStr) {
                return '';
            }
            return (moment(dateStr).format('YYYY-MM-DD') + ' (' + moment(dateStr).fromNow() + ')');
        };
    });
})();
