var app;
(function (app) {
    var giteditor;
    (function (giteditor) {
        var XmlRenderer = (function () {
            function XmlRenderer($q, $sce, highlightService) {
                this.$q = $q;
                this.$sce = $sce;
                this.highlightService = highlightService;
                this.extensions = ['.xml'];
                this.requiresContent = true;
            }
            XmlRenderer.prototype.render = function (content) {
                if (!content.body || content.body.trim() === '')
                    return {
                        isAsync: false,
                        immidate: "<pre class=\"xml\">" + this.escapeHTML(content.body) + "</pre>",
                        eventual: this.$q.reject(false)
                    };
                return {
                    isAsync: true,
                    immidate: "<pre class=\"xml\">" + this.escapeHTML(content.body) + "</pre>",
                    eventual: this.highlightService.highlight(content.body)
                };
            };
            XmlRenderer.prototype.renderFile = function (file) { throw new Error("Not implemented"); };
            XmlRenderer.prototype.escapeHTML = function (unsafe) {
                return unsafe.replace(/[&<>"']/g, function (m) {
                    switch (m) {
                        case '&':
                            return '&amp;';
                        case '<':
                            return '&lt;';
                        case '>':
                            return '&gt;';
                        case '"':
                            return '&quot;';
                        default:
                            return '&#039;';
                    }
                });
            };
            return XmlRenderer;
        }());
        giteditor.XmlRenderer = XmlRenderer;
        function factory($q, $sce, highlightService) {
            return new XmlRenderer($q, $sce, highlightService);
        }
        angular
            .module('app.giteditor')
            .service('app.giteditor.renderersXmlRenderer', ['$q', '$sce', 'app.giteditor.IHighlighterService', factory]);
    })(giteditor = app.giteditor || (app.giteditor = {}));
})(app || (app = {}));
