var app;
(function (app) {
    var giteditor;
    (function (giteditor) {
        var JsonRenderer = (function () {
            function JsonRenderer($q, $sce, highlightService) {
                this.$q = $q;
                this.$sce = $sce;
                this.highlightService = highlightService;
                this.extensions = ['.json'];
                this.requiresContent = true;
            }
            JsonRenderer.prototype.render = function (content) {
                var prefix = "";
                var contentString = content.body;
                var immidateStr = "";
                try {
                    immidateStr = JSON.stringify(JSON.parse(content.body), null, 2);
                }
                catch (error) {
                    prefix = "<div style=\"color:red; margin-top:15px;\"><i class=\"fa fa-warning\"></i> Syntax Error: " + error + "</div>";
                    immidateStr = contentString;
                }
                return {
                    isAsync: true,
                    immidate: prefix + '<pre class="json">' + immidateStr + '</pre>',
                    eventual: this.highlightService.highlight(immidateStr).then(function (html) { console.log(html); return prefix + html; })
                };
            };
            JsonRenderer.prototype.renderFile = function (file) { throw new Error("Not implemented"); };
            return JsonRenderer;
        }());
        giteditor.JsonRenderer = JsonRenderer;
        function factory($q, $sce, hs) {
            return new JsonRenderer($q, $sce, hs);
        }
        angular
            .module('app.giteditor')
            .service('app.giteditor.renderersJsonRenderer', ['$q', '$sce', 'app.giteditor.IHighlighterService', factory]);
    })(giteditor = app.giteditor || (app.giteditor = {}));
})(app || (app = {}));
