(function () {
    'use strict';
    angular
        .module('app.login')
        .config(['$stateProvider', config]);
    function config($stateProvider) {
        $stateProvider.state('login', {
            url: '/browse/login',
            templateUrl: '/public/app/login/login.html',
            controller: 'app.login.LoginController',
            controllerAs: 'vm'
        });
    }
})();
