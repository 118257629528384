var app;
(function (app) {
    var dp;
    (function (dp) {
        'use strict';
        var CustomersController = (function () {
            function CustomersController(directoryService) {
                var _this = this;
                this.directoryService = directoryService;
                this.selectedItemChanged = function (customer) {
                    if (customer)
                        _this.directoryService.goPath('/sites/' + customer.code);
                };
                this.querySearch = function (search) {
                    if (!search)
                        return _this.customers;
                    var regex = new RegExp(search.replace(' ', '.*').replace('*', '.*'), 'ig');
                    function matchCustomer(customer) {
                        return regex.test(customer.code + customer.name);
                    }
                    return _.filter(_this.customers, matchCustomer);
                };
                var file = {
                    name: 'siteCodes.json',
                    fullname: '/siteCodes.json',
                    exists: true,
                    extension: '.json',
                    type: 'File'
                };
                directoryService.loadFile(file).then(function (fileContent) {
                    var siteCodes = (typeof fileContent.body == "object" ? fileContent.body : JSON.parse(fileContent.body));
                    var customers = [];
                    for (var _i = 0, _a = _.filter(siteCodes, { IsActive: true }); _i < _a.length; _i++) {
                        var s = _a[_i];
                        customers.push({
                            code: s.Code,
                            name: s.CustomerName
                        });
                    }
                    _this.customers = _.sortBy(customers, ['name']);
                });
            }
            CustomersController.$inject = ['app.giteditor.IGitDirectoryService'];
            return CustomersController;
        }());
        var CustomersDirective = (function () {
            function CustomersDirective($templateRequest, $compile) {
                this.$templateRequest = $templateRequest;
                this.$compile = $compile;
                this.controllerAs = 'vm';
                this.controller = 'app.dp.CustomersController';
                this.templateUrl = '/public/app/dp/customers.html';
                this.restrict = 'E';
                this.scope = {};
            }
            CustomersDirective.$inject = ['$templateRequest', '$compile'];
            return CustomersDirective;
        }());
        var slice = [].slice;
        function buildDirectiveFactory(ctor) {
            var factory;
            factory = function () {
                var args;
                args = 1 <= arguments.length ? slice.call(arguments, 0) : [];
                return (function (func, args, ctor) {
                    ctor.prototype = func.prototype;
                    var child = new ctor, result = func.apply(child, args);
                    return Object(result) === result ? result : child;
                })(ctor, args, function () { });
            };
            factory.$inject = ctor.$inject;
            return factory;
        }
        ;
        angular
            .module('app.dp')
            .directive('dpCustomers', buildDirectiveFactory(CustomersDirective))
            .controller('app.dp.CustomersController', CustomersController);
    })(dp = app.dp || (app.dp = {}));
})(app || (app = {}));
